import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const OurWorld = ({ content_all }) => {
  const items = [
    {
      image: content_all?.zigo_our_world?.[0]?.image,
      title: "Hair Products",
      subtitle: "ANTI REFLET JAUNE",
      label: "715",
    },
    {
      image: content_all?.zigo_our_world?.[1]?.image,
      title: "Natural and Certified Organic Skincare",
      description:
        "Let's Get Ever Beauty. 56% increase in skin protection. 83% increase in hydration",
      label: "Learn More",
    },
    {
      image: content_all?.zigo_our_world?.[2]?.image,
      title: "Body Spray",
      subtitle: "ANTI REFLET JAUNE",
      label: "300ml",
    },
    {
      image: content_all?.zigo_our_world?.[3]?.image,
      title: "Summer Sunscreen",
      subtitle: "Up to 30% Off On Selected Brands",
      label: "Shop Now",
    },
    {
      image: content_all?.zigo_our_world?.[4]?.image,
      title: "Electronics",
      subtitle: "ANTI REFLET JAUNE",
    },
  ];

  return (
    <Section>
      <div className="home_wrapper">
        <h2 className="section-title">Exploring Our World</h2>
        <div className="grid-container">
          <div className="grid-item item1">
            <Link to="/products">
              <img src={process.env.REACT_APP_BASE + items[0]?.image} alt="" />
            </Link>
          </div>
          <div className="grid-item item2">
            <Link to="/products">
              <img src={process.env.REACT_APP_BASE + items[1]?.image} alt="" />
            </Link>
          </div>
          <div className="grid-item item3">
            <Link to="/products">
              <img src={process.env.REACT_APP_BASE + items[2]?.image} alt="" />
            </Link>
          </div>
          <div className="grid-item item4">
            <Link to="/products">
              <img src={process.env.REACT_APP_BASE + items[3]?.image} alt="" />
            </Link>
          </div>
          <div className="grid-item item5">
            <Link to="/products">
              <img src={process.env.REACT_APP_BASE + items[4]?.image} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default OurWorld;

const Section = styled.div`
  /* height: 120vh; */
  padding: 0 40px;
  text-align: center;

  .home_wrapper {
    padding: 50px 80px;
    height: 100%;
    max-width: 90%;
    margin: 0 auto;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 20px;
    font-family: "Josefin Sans", sans-serif;
  }

  .grid-container {
    display: grid;
    grid-template-areas:
      "item1 item2 item2"
      "item3 item2 item2"
      "item4 item4 item5";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 25px;
    height: 100%;
  }

  .grid-item {
    background-color: #e8e8e8;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .item1 {
    grid-area: item1;
  }

  .item2 {
    grid-area: item2;
  }

  .item3 {
    grid-area: item3;
  }

  .item4 {
    grid-area: item4;
  }

  .item5 {
    grid-area: item5;
  }

  @media (max-width: 768px) {
    /* padding: 40px 10px;
    height: auto;
    .grid-container {
      grid-template-areas:
        "item1"
        "item2"
        "item3"
        "item4"
        "item5";
      grid-template-columns: 1fr;
      gap: 15px;
      height: auto;
    } */

    .home_wrapper {
    padding: 0px 20px;
    height: auto;
  }

    /* .grid-item {
      height: 400px;
      img {
        object-fit: fill;
      }
    } */
  }

  /* @media (max-width: 475px) {
    padding: 40px 10px;
    height: auto;
    .grid-container {
      grid-template-areas:
        "item1"
        "item2"
        "item3"
        "item4"
        "item5";
      grid-template-columns: 1fr;
      gap: 15px;
      height: auto;
    }

    .home_wrapper {
    padding: 0px 0px;
    height: auto;
  }

    .grid-item {
      img {
        object-fit: cover;
      }
    }
  } */

    @media (max-width: 475px) {
    padding: 40px 10px;
    height: auto;

    .grid-container {
      grid-template-areas:
        "item1"
        "item2"
        "item3"
        "item4"
        "item5";
      grid-template-columns: 1fr;
      gap: 15px;
      height: auto;
    }

    .grid-item {
      /* Same height adjustment for smaller mobile screens */
      height: 250px;
    }

    .home_wrapper {
      padding: 0px 0px;
      height: auto;
    }

    .grid-item {
      img {
        object-fit: fill;
      }
    }
  }
`;
