import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RightOutlined, LeftOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useNavigate, Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import '../../Assets/Fonts/fonts.css';
import IMG1 from "../../Assets/Images/Zigoo/Rectangle 691.png";
import IMG2 from "../../Assets/Images/Zigoo/Rectangle 692.png";
import IMG3 from "../../Assets/Images/Zigoo/Rectangle 693.png";

let user = "";

const OurSignatureCollections1 = ({ content_all }) => {
  console.log("OurSignatureCollection", content_all)

  const [visibleContent, setVisibleContent] = useState(null);

  const getUser = localStorage.getItem("user");
  const api = new API();
  const navigate = useNavigate();

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const Favorite_Products = [];

  if (content_all && content_all?.zigo_oursign_product) {
    content_all?.zigo_oursign_product.forEach((item) => {
      if (!Favorite_Products.some((favoriteItem) => favoriteItem?.value === item?.value)) {
        Favorite_Products.push(item);
      }
    });
  }

  // console.log("Favorite_Products", Favorite_Products);

  const products = useSelector(
    (state) => state?.product?.product?.data
  );
  // console.log("products", products)

  const FilteredPro = products?.filter((item) =>
    Favorite_Products?.some((pro) => pro?.value === item?._id)
  );

  console.log("FilteredPro", FilteredPro)
  
  const handleToggle = (index) => {
    if (visibleContent === index) {
      setVisibleContent(null); // Hide the content if already visible
    } else {
      setVisibleContent(index); // Show the content for the clicked item
    }
  };

  return (
    <WhyUsSectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">  
        <div className="ViewAll">
          <p>{content_all?.title_1}</p>
        </div>        
          <p className="Title">
            {content_all?.title_2}
          </p>         
        </div>        
        <div className="WhyUs_Section_Main">
          <div className="WhyUs_Section">
            {FilteredPro?.slice(0, 3)?.map((item, index) => (
              <div key={index} className="WhyUs_Section_Box">
                <div className="WhyUs_Section_Box_Image">
                  <Link to={`/product/${item?._id}`}> 
                    <img src={api?.rootUrl1 + item?.images} />
                  </Link>
                  <div className="toggle-icon">
                    {visibleContent === index ? (
                      <MinusCircleOutlined
                        onClick={() => handleToggle(index)}
                        style={{ color: "#0bb68c", backgroundColor: "white", borderRadius: "50%" }}
                      />
                    ) : (
                      <PlusCircleOutlined onClick={() => handleToggle(index)} style={{ color: "#0bb68c", backgroundColor: "white", borderRadius: "50%" }} />
                    )}
                  </div>
                </div>      
                {visibleContent === index && (          
                <div className="WhyUs_Section_Box_Pro_Content">
                  <div className='Pro_img'>
                    <Link to={`/product/${item?._id}`}>
                      <img src={api?.rootUrl1 + item?.prospecdocs?.[0]?.product_images?.[0]?.image_path} />
                    </Link>
                  </div>
                  <div className="ContentBox">
                    <h5 className="Title">{item?.product_name}</h5>
                    <p className="Price">Rs.{item?.prospecdocs?.[0]?.sp}/-</p>
                  </div>
                  <div className="toggle-icon-below">
                    <MinusCircleOutlined
                      onClick={() => handleToggle(index)}
                      style={{ color: "#0bb68c", backgroundColor: "white", borderRadius: "50%", position: "absolute", bottom: "-25%", right: "50%" }}
                    />
                  </div>
                </div> 
                )}               
              </div>
            ))}
          </div>
        </div>
      </div>
    </WhyUsSectionAlign>
  );
};

export default OurSignatureCollections1;

const WhyUsSectionAlign = styled.div`
  /* margin-bottom: 80px; */
  margin: 40px 0;

  .home_wrapper {
    padding: 0 80px;
    max-width: 90%;
  }

  .Top_Title {
    text-align: center;
    padding: 0px 0 0px 0;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 50%;
    margin: auto;

    p {
      font-family: Inter;
      font-size: 25px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  .ViewAll {
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      /* text-decoration: underline; */
    }
  }

  .Title{
    font-family: "Josefin Sans", sans-serif;
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;    
  }

  .WhyUs_Section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1%;
    flex-wrap: wrap;
  }

  .WhyUs_Section_Box {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* border: 1px solid #ECECEC; */
    position: relative;
  }

  .WhyUs_Section_Box_Image {
    width: 100%;
    height: 60vh; /* Fixed height for the image box */
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #ECECEC;
  }

  .WhyUs_Section_Box_Image img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
  }

  .toggle-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    cursor: pointer;
  }

  .toggle-icon-below {
  display: flex;
  justify-content: center;
  margin-top: 10px; 
  font-size: 24px;
  cursor: pointer;
}

  .WhyUs_Section_Box_Title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    line-height: normal;
    /* margin-bottom: 10px; */
    height: 2.5em; /* Fixed height for the title box */
    /* vertical-align: middle !important; */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .WhyUs_Section_Box_Description {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
    height: 2em; 
    overflow: hidden;
  }

  /* .WhyUs_Section_Box_Pro_Content {
    display: flex;
    align-items: center;
    justify-content: center;    
    height: 30%;
    width: 75%;
    border: 1px solid #000;
    padding: 2%;
  } */

    .WhyUs_Section_Box_Pro_Content {
    position: absolute; /* Absolutely position the content */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(
      -50%,
      -50%
    ); /* Translate back by 50% to achieve perfect centering */
    width: 80%; /* Adjust width as necessary */
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ececec;
    z-index: 1; /* Ensure it stays on top */
    display: flex;
    /* flex-direction: column; */
    align-items: center; /* Center content inside the box */
    justify-content: center;
    gap: 10%;
    border-radius: 5%;
  }

  .Pro_img {
    width: 100%;
    height: 100%;    
    /* border: 1px solid #000; */
    padding: 2%;
    background-color: #ececec;
    border-radius: 5%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5%;
    }
  }

  .Price {
    font-size: 12px;
    font-weight: 500;
  }

  .Title {
    font-size: 14px;
    font-weight: 700;
  }

  @media (max-width: 1200px) {
    .home_wrapper {
      padding: 0 60px;
    }

    /* .Top_Title p {
      font-size: 22px;
    } */
  }

  @media (max-width: 991px) {
    .home_wrapper {
      padding: 0 40px;
    }

    /* .Top_Title p {
      font-size: 20px;
    } */

    .WhyUs_Section_Box {
      width: 45%;
    }

    .WhyUs_Section_Box_Title {
      font-size: 18px;
    }

    .WhyUs_Section_Box_Description {
      font-size: 9px;
    }
  }

  @media (max-width: 767px) {
    .home_wrapper {
      padding: 0 20px;
    }

    /* .Top_Title p {
      font-size: 18px;
    } */

    .WhyUs_Section_Box {
      width: 100%;
      margin-bottom: 20px;
    }

    .WhyUs_Section_Box_Image {
      height: 50vh; /* Adjusted height for mobile */
    }

    .WhyUs_Section_Box_Title {
      font-size: 16px;
    }

    .WhyUs_Section_Box_Description {
      font-size: 8px;
    }
  }

  @media (max-width: 540px) {
    .home_wrapper {
      padding: 0 0px;
    }

    .Top_Title {
      width: 100%;
    }

    /* .Top_Title p {
      font-size: 16px;
    } */

    .WhyUs_Section_Box {
      width: 100%;
      margin-bottom: 20px;
    }

    .WhyUs_Section_Box_Image {
      height: 50vh; /* Adjusted height for smaller screens */
    }

    .WhyUs_Section_Box_Title {
      font-size: 14px;
    }

    .WhyUs_Section_Box_Description {
      font-size: 7px;
    }
  }

  @media (max-width: 360px) {
    .home_wrapper {
      padding: 0 px;
    }

    .Top_Title {
      width: 100%;
    }

    /* .Top_Title p {
      font-size: 14px;
    } */

    .WhyUs_Section_Box {
      width: 100%;
      margin-bottom: 20px;
    }

    .WhyUs_Section_Box_Image {
      height: 50vh; /* Adjusted height for the smallest screens */
    }

    .WhyUs_Section_Box_Title {
      font-size: 12px;
    }

    .WhyUs_Section_Box_Description {
      font-size: 6px;
    }
  }
`;
