
import React from 'react';
import { useSelector } from 'react-redux'
import BannerMain from './BannerMain';
import DynamicSection from './DynamicSection';
import ParallaxMain from './ParallaxMain';
import TopCategoryMain from './TopCategoryMain';
import FeatureProductMain from './FeatureProductMain';
import ProductsAndServiceMain from './ProductsAndServicesMain';
import styled from 'styled-components';
import Blog from './Blogs/Blog';
import GiftSection7 from '../GIftSection/GiftSection7';
import GiftSectionMain from './GiftSectionMain';
import GiftStoriesSectionMain from './GiftStoriesSectionMain';
import OfferBannerMain from './OfferBannerMain';
import GiftProductsSectionMain from './GiftProductsSectionMain';
import WhyWeUsSectionMain from './WhyWeUsSectionMain';
import BrandMain from './BrandMain';
import TemplateMain from './TemplateMain';
import TestDemoSwitchMain from './TestDemoSwitchMain';



const Home = () => {
  const homecustom = useSelector(
    (state) => state?.homeCustomSlice?.homecustom?.data[0]
  );
  // const templateorder = useSelector((state) => state?.templateOrderArraySlice?.templateorder?.data[state?.templateOrderArraySlice?.templateorder?.data?.length - 1]);
  const banner = useSelector((state) => state?.banner?.banner?.data);
  const category = useSelector((state) => state?.category?.category?.data);
  // const product = useSelector((state) => state?.product?.product?.data)
  const product = useSelector((state) => state?.product?.product?.data[0].data);
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const socialmedia = useSelector(
    (state) => state?.socialMedia?.socialmedia?.data
  );
  // console.log("templateorder==>",templateorder)
  // console.log("homeeeeeee",homecustom)

  const cardTypes = localStorage.getItem("cardTypes");
  const cardTypes1 = JSON.parse(cardTypes) || [];

  // console.log("cardTypes1", cardTypes1)

  var SelectedSections = [  
      "HeaderWithTransparentBackground",  
      "TopBannerWithGradientbgImgTitileDescriptionAndButtonAndMarquee",    
      "TopCategoryOvalDesignwithSlider",   
      "OurFavourites",  
      "BannerWithTwoImages",   
      "NewArrival2",  
      "OfferBanner2",  
      "TrendingOfMonth1",
      "WhyUsSection2",
      "HCGirlsHub2",
      "FP10",
      "ClientReview",
      "Instagram1",  
      "Footer10"    
  ];
  var SelectedSections1 = [  
      "TC14",   
      "SingleTextSection",  
      "Banner14",
      "Productcosmetics",
      "OurSignatureCollections1",
      "ShopByCategory",
      "OurSignatureCollections2",
      "OurWorld",
      "BeautyProducts",
      "FP14",
      "ReadingBeauty",
      "OfferBannerZigoo",
      "ZigooAddress",
  ];

  // console.log("SelectedSections", SelectedSections)

  return (
    <HomeSection style={{overflowX: "hidden !important"}}>
      {/* <TemplateMain theme={homecustom?.theme}/> */}
      {/* <TopCategoryMain topcategory_type={homecustom?.topcategory_type ? homecustom?.topcategory_type : ""} category={category} />
      <BannerMain banner_type={homecustom?.banner_type ?homecustom?.banner_type : ""} banner={banner} />
      <div className='section_wrap'>
      <ProductsAndServiceMain />
      <GiftSectionMain />
      <GiftStoriesSectionMain />
      <OfferBannerMain />
      <GiftProductsSectionMain />
      <WhyWeUsSectionMain />
      <BrandMain />
      <FeatureProductMain featuredproduct_type={homecustom?.featuredproduct_type ? homecustom?.featuredproduct_type : ""} featureProduct={product} />
      <DynamicSection   />
      <Blog />
      </div> */}

      {/* <ParallaxMain parallax_type={homecustom?.parallax_type?homecustom?.parallax_type:""} /> */}
      {/* {
        SelectedSections1.map((item, index) => {
          return <TestDemoSwitchMain key={index} section_type={item} theme={homecustom?.theme} />
        })
      } */}
      {
        cardTypes1.map((item, index) => {
          return <TestDemoSwitchMain key={index} section_type={item} theme={homecustom?.theme} />
        })
      }
    </HomeSection>
  );
};

export default Home;

const HomeSection = styled.section`
  overflow-x: hidden !important;
  .section_wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* margin: 20px 0; */
    gap: 100px 0;
    overflow-x: hidden !important;
  }
`;
