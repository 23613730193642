import React, { useState } from "react";
import CheckoutStepper from "../Checkout/CheckoutStepper";
import CheckoutCard from "../Checkout/CheckoutCard";
import styled from "styled-components";

const CheckoutMain = () => {

  const [totalAmount, setTotalAmount] = useState(0);
  const [refresh, setRefresh] = useState(0); // State to trigger refresh

  const handleCartChange = () => {
    console.log('Cart changed, refreshing...');
    setRefresh(refresh + 1);
  };

  console.log("refrersh:", refresh);

  return (
    <CheckoutMainAlign>
      <div className="checkout_main">
        <div className="Checkout_Left">
          <CheckoutStepper totalAmount={totalAmount} onCartChange={handleCartChange} />
        </div>
        <div className="Checkout_Right">
          <CheckoutCard setTotalAmount={setTotalAmount} totalAmount={totalAmount} refresh={refresh} />
        </div>
      </div>
    </CheckoutMainAlign>
  );
};

export default CheckoutMain;

const CheckoutMainAlign = styled.div`
 overflow-x: hidden;

margin: 7% 0 10% 0;

  .checkout_main {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .Checkout_Left {
    width: 94%;
  }

  .Checkout_Right {
    width: 28%;    
  }

  @media screen and (max-width: 1024px) {
    margin: 10% 0 10% 0;    
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    .checkout_main {
      padding: 0 40px;
      display: grid;
      grid-template-columns: 1fr;
      /* border: 1px solid red; */
    }    
    .Checkout_Left {
      /* border: 1px solid red; */
      width: 100%;
    }
    .Checkout_Right {
      width: 100% !important; 
      /* border: 1px solid red; */
    }
    .billing_address_section {
      gap: 0 !important;
      padding: 10px 2px;
    }
    .wrapper {
      padding: 0 10px;
    }
    .ant-divider-horizontal {
      margin: 15px 0;
    }
    .StepperLabel-incomplete {
      padding: 10px;
    }
    .fQmUgE {
      margin: 30px 0 0 0;
    }
    .css-8t49rw-MuiStepConnector-line {
      min-height: 10px;
    }
    .cYBwvA {
      padding-right: 0px;
    }
    .cart_list {
      padding: 0px 20px;
    }
    .cart_box_right {
      width: 70%;
      padding: 0 40px 0 0;
      gap: 0;
    }
    .cart_box:not(:last-child) {
      padding: 0 0 0 0;
    }
  }

  @media screen and (max-width: 540px) {
    max-width: 540px;
    .checkout_main {
      padding: 0 40px;
      display: grid;
      grid-template-columns: 1fr;
      /* border: 1px solid red; */
    }    
    .Checkout_Left {
      /* border: 1px solid red; */
      width: 100%;
    }
    .Checkout_Right {
      width: 100% !important; 
      /* border: 1px solid red; */
    }
    .billing_address_section {
      gap: 0 !important;
      padding: 10px 2px;
    }
    .wrapper {
      padding: 0 10px;
    }
    .ant-divider-horizontal {
      margin: 15px 0;
    }
    .StepperLabel-incomplete {
      padding: 10px;
    }
    .fQmUgE {
      margin: 30px 0 0 0;
    }
    .css-8t49rw-MuiStepConnector-line {
      min-height: 10px;
    }
    .cYBwvA {
      padding-right: 0px;
    }
    .cart_list {
      padding: 0px 20px;
    }
    .cart_box_right {
      width: 70%;
      padding: 0 40px 0 0;
      gap: 0;
    }
    .cart_box:not(:last-child) {
      padding: 0 0 0 0;
    }
    .css-1f5ro5o-MuiButtonBase-root-MuiButton-root{
      margin: 0 0 0 0;
      padding: 3px 10px 3px 10px;
    }
    .ant-list-item{
      padding: 0px 0 0px 0;
      /* flex-direction: column; */
    }   
    .ant-list-item-action{
      margin-inline-start: 0px;
    } 
  }

  @media screen and (max-width: 425px) {
    max-width: 425px; 
    margin-top: 100px;
    .checkout_main {
      padding: 0 10px;
      display: grid;
      grid-template-columns: 1fr;
      /* border: 1px solid red; */
    }    
    .Checkout_Left {
      /* border: 1px solid red; */
      width: 94%;
    }
    .Checkout_Right {
      width: 100% !important; 
      /* border: 1px solid red; */
    }
    .cart_right {
      font-size: small;
    }
    .billing_address_section {
      gap: 0 !important;
      padding: 10px 2px;
    }
    .wrapper {
      padding: 0 10px;
    }
    .ant-divider-horizontal {
      margin: 15px 0;
    }
    .StepperLabel-incomplete {
      padding: 5px;
    }
    .fQmUgE {
      margin: 30px 0 0 0;
    }
    .css-8t49rw-MuiStepConnector-line {
      min-height: 10px;
    }
    .cYBwvA {
      padding-right: 0px;
    }
    .cart_list {
      padding: 0px 10px;
    }
    .cart_box_right {
      width: 75%;
      padding: 0 0px 0 0;
      gap: 0;
    }
    .cart_box_right h2{
      font-size: 15px;      
    }
    .cart_box_right h4{
      font-size: 14px;
    }
    .price .sp{
      font-size: 14px !important;
    }
    .price .mrp{
      font-size: 12px !important;
    }
    .price .qty{
      font-size: 14px !important;
    }
    .Tax{
      font-size: 14px !important;      
    }
    .cart_price_description p {
      font-size: 14px;
    }
    .cart_specification .ant-tag{
      font-size: 12px !important;
    }
    .cart_box:not(:last-child) {
      padding: 0 0 0 0;
    }
    .css-1f5ro5o-MuiButtonBase-root-MuiButton-root{
      margin: 0 0 0 0;
      padding: 3px 10px 3px 10px;
      font-size: 12px;
    }
    .ant-list-item{
      padding: 0px 0 0px 0;
      flex-direction: column;
    }   
    .ant-list-item-meta-title, .ant-list-item-meta-description{
      width: 243px;
    }
    .ant-list-item-action{
      margin-inline-start: 0px !important;
      width: 290px;
      margin-top: 10px;
    } 
    .gzEoJV{
      padding: 0px 0 0px 0;
    }  
    .address_label, .MuiStepIcon-text{
      font-size: 14px !important;
    } 
    .css-14yr603-MuiStepContent-root {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 375px) {
    max-width: 375px;
    margin-top: 100px;
    .checkout_main {
      padding: 0 10px;
      display: grid;
      grid-template-columns: 1fr;
      /* border: 1px solid red; */
    }    
    .Checkout_Left {
      /* border: 1px solid red; */
      width: 100%;
    }
    .Checkout_Right {
      width: 100% !important; 
      /* border: 1px solid red; */
    }
    .cart_right {
      font-size: small;
    }
    .billing_address_section {
      gap: 0 !important;
      padding: 10px 2px;
    }
    .wrapper {
      padding: 0 10px;
    }
    .ant-divider-horizontal {
      margin: 15px 0;
    }
    .StepperLabel-incomplete {
      padding: 5px;
    }
    .fQmUgE {
      margin: 30px 0 0 0;
    }
    .css-8t49rw-MuiStepConnector-line {
      min-height: 10px;
    }
    .cYBwvA {
      padding-right: 0px;
    }
    .cart_list {
      padding: 0px 10px;
    }
    .cart_box_right {
      width: 75%;
      padding: 0 0px 0 0;
      gap: 0;
    }
    .cart_box_right h2{
      font-size: 15px;      
    }
    .cart_box_right h4{
      font-size: 14px;
    }
    .price .sp{
      font-size: 14px !important;
    }
    .price .mrp{
      font-size: 12px !important;
    }
    .price .qty{
      font-size: 14px !important;
    }
    .Tax{
      font-size: 14px !important;      
    }
    .cart_price_description p {
      font-size: 14px;
    }
    .cart_specification .ant-tag{
      font-size: 12px !important;
    }
    .cart_box:not(:last-child) {
      padding: 0 0 0 0;
    }
    .css-1f5ro5o-MuiButtonBase-root-MuiButton-root{
      margin: 0 0 0 0;
      padding: 3px 10px 3px 10px;
      font-size: 12px;
    }
    .ant-list-item{
      padding: 0px 0 0px 0;
      flex-direction: column;
    }   
    .ant-list-item-meta-title, .ant-list-item-meta-description{
      width: 245px;
    }
    .ant-list-item-action{
      margin-inline-start: 0px;
      width: 240px;
      margin-top: 10px;
    } 
    .gzEoJV{
      padding: 0px 0 0px 0;
    }  
    .address_label, .MuiStepIcon-text{
      font-size: 14px !important;
    } 
    .css-14yr603-MuiStepContent-root {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 320px) {
    max-width: 320px;
    margin-top: 100px;
    .checkout_main {
      padding: 0 10px;
      display: grid;
      grid-template-columns: 1fr;
      /* border: 1px solid red; */
    }    
    .Checkout_Left {
      /* border: 1px solid red; */
      width: 100%;
    }
    .Checkout_Right {
      width: 100% !important; 
      /* border: 1px solid red; */
    }
    .cart_right {
      font-size: small;
    }
    .billing_address_section {
      gap: 0 !important;
      padding: 10px 2px;
    }
    .wrapper {
      padding: 0 10px;
    }
    .ant-divider-horizontal {
      margin: 15px 0;
    }
    .StepperLabel-incomplete {
      padding: 5px;
    }
    .fQmUgE {
      margin: 30px 0 0 0;
    }
    .css-8t49rw-MuiStepConnector-line {
      min-height: 10px;
    }
    .cYBwvA {
      padding-right: 0px;
    }
    .cart_list {
      padding: 0px 10px;
    }
    .cart_box_right {
      width: 75%;
      padding: 0 0px 0 0;
      gap: 0;
    }
    .cart_box_right h2{
      font-size: 15px;      
    }
    .cart_box_right h4{
      font-size: 14px;
    }
    .price .sp{
      font-size: 14px !important;
    }
    .price .mrp{
      font-size: 12px !important;
    }
    .price .qty{
      font-size: 14px !important;
    }
    .Tax{
      font-size: 14px !important;      
    }
    .cart_price_description p {
      font-size: 14px;
    }
    .cart_specification .ant-tag{
      font-size: 12px !important;
    }
    .cart_box:not(:last-child) {
      padding: 0 0 0 0;
    }
    .css-1f5ro5o-MuiButtonBase-root-MuiButton-root{
      margin: 0 0 0 0;
      padding: 3px 10px 3px 10px;
      font-size: 12px;
    }
    .ant-list-item{
      padding: 0px 0 0px 0;
      flex-direction: column;
    }   
    .ant-list-item-meta-title, .ant-list-item-meta-description{
      width: 210px;
    }
    .ant-list-item-action{
      margin-inline-start: 0px;
      width: 185px;
      margin-top: 10px;
    } 
    .gzEoJV{
      padding: 0px 0 0px 0;
    }  
    .address_label, .MuiStepIcon-text{
      font-size: 14px !important;
    } 
    .css-14yr603-MuiStepContent-root {
      padding-left: 10px;
    }
  }

`;
