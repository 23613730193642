import React, {useState} from "react";
import image1 from "../../Assets/Images/GirlsHub Template/insta-1.png";
import image2 from "../../Assets/Images/GirlsHub Template/insta-2.png";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const Instagram1 = ({followusoninstagram, content_all}) => {
  // console.log("followusoninstagram", followusoninstagram);
  // console.log("content_all==>Instagram1", content_all)
  const [hover, setHover] = useState(null);

  const api = new API();

  const instagram = [
    {image: content_all?.insta_img_1?.image, link: content_all?.insta_link_1},
    {image: content_all?.insta_img_2?.image, link: content_all?.insta_link_2},
    {image: content_all?.insta_img_3?.image, link: content_all?.insta_link_3},
    {image: content_all?.insta_img_4?.image, link: content_all?.insta_link_4},
  ];

  return (
    <Instagram>
      <div className="home_wrapper">
        <div className="Top_Title">
          <span className="Title">Follow Us On Instagram</span>
        </div>
        <div className="Instagram_Main">
          {instagram?.map((item, index) => (
            <div
              className="InstagramContainer"
              key={index}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
              onClick={() => window.open(item?.link, "_blank")}              
            >
              <img src={api?.rootUrl1 + item?.image} alt="instagram" />
              
              {hover === index && (
                <div className="Hover_Content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 63 64"
                    fill="none"
                  >
                    <path
                      d="M44.8223 15.1982C44.8223 14.3163 45.1726 13.4705 45.7962 12.847C46.4198 12.2234 47.2655 11.873 48.1474 11.873C49.0293 11.873 49.8751 12.2234 50.4987 12.847C51.1222 13.4705 51.4726 14.3163 51.4726 15.1982C51.4726 16.0801 51.1222 16.9258 50.4987 17.5494C49.8751 18.173 49.0293 18.5233 48.1474 18.5233C47.2655 18.5233 46.4198 18.173 45.7962 17.5494C45.1726 16.9258 44.8223 16.0801 44.8223 15.1982Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M31.523 16.0303C27.334 16.0303 23.3166 17.6943 20.3546 20.6564C17.3926 23.6184 15.7285 27.6358 15.7285 31.8247C15.7285 36.0137 17.3926 40.0311 20.3546 42.9931C23.3166 45.9551 27.334 47.6192 31.523 47.6192C35.7119 47.6192 39.7293 45.9551 42.6914 42.9931C45.6534 40.0311 47.3174 36.0137 47.3174 31.8247C47.3174 27.6358 45.6534 23.6184 42.6914 20.6564C39.7293 17.6943 35.7119 16.0303 31.523 16.0303ZM20.7162 31.8247C20.7162 28.9586 21.8548 26.2099 23.8815 24.1832C25.9081 22.1566 28.6569 21.018 31.523 21.018C34.3891 21.018 37.1378 22.1566 39.1645 24.1832C41.1912 26.2099 42.3297 28.9586 42.3297 31.8247C42.3297 34.6909 41.1912 37.4396 39.1645 39.4663C37.1378 41.4929 34.3891 42.6315 31.523 42.6315C28.6569 42.6315 25.9081 41.4929 23.8815 39.4663C21.8548 37.4396 20.7162 34.6909 20.7162 31.8247Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M49.0045 1.34406C37.3844 0.0558853 25.6574 0.0558853 14.0373 1.34406C7.34706 2.09222 1.94369 7.36259 1.15896 14.086C-0.219652 25.8723 -0.219652 37.7791 1.15896 49.5654C1.94369 56.2888 7.34374 61.5592 14.0373 62.3074C25.6575 63.5942 37.3843 63.5942 49.0045 62.3074C55.6947 61.5592 61.0981 56.2888 61.8829 49.5654C63.2617 37.7791 63.2617 25.8723 61.8829 14.086C61.0981 7.36259 55.6981 2.09222 49.0045 1.34406ZM14.5892 6.29854C25.8426 5.05118 37.1992 5.05118 48.4526 6.29854C52.875 6.79731 56.4163 10.2854 56.9317 14.6679C58.2649 26.0676 58.2649 37.5838 56.9317 48.9835C56.6651 51.1284 55.6832 53.1207 54.1447 54.6387C52.6062 56.1568 50.6008 57.1118 48.4526 57.3496C37.1993 58.597 25.8426 58.597 14.5892 57.3496C12.441 57.1118 10.4356 56.1568 8.8971 54.6387C7.35856 53.1207 6.37669 51.1284 6.11011 48.9835C4.77688 37.5838 4.77688 26.0676 6.11011 14.6679C6.37669 12.5231 7.35856 10.5307 8.8971 9.0127C10.4356 7.49467 12.441 6.53631 14.5892 6.29854Z"
                      fill="white"
                    />
                  </svg>
                  <p className="Title">Follow Us On Instagram</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Instagram>
  );
};

export default Instagram1;

const Instagram = styled.div`
  .home_wrapper {
    padding: 60px 60px;
  }

  .Top_Title {
    color: #000;
    text-align: left;
    font-family: Moneta;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 0 20px 0;
  }

  .Instagram_Main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* align-items: center; */
    /* justify-content: space-evenly; */
    gap: 20px;
  }

  .InstagramContainer {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .Hover_Content {
        display: grid;        
        place-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      text-align: center;
      padding: 80px 0;
      border-radius: 8px;
      z-index: 1;
      line-height: 10px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 1024px;
    .Instagram_Main {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    max-width: 912px;
    .Instagram_Main {
      grid-template-columns: repeat(2, 1fr);
    }    
  }

  @media screen and (min-width: 768px) and (max-width: 911px) {
    max-width: 800px;
    .Instagram_Main {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Title {
      font-size: 28px;
    }
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    max-width: 425px;
    /* border: 1px solid red; */
    /* margin-left: 50px; */
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
      margin: 0 auto !important;
      /* border: 1px solid red; */
    }
    .Top_Title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 414px) and (max-width: 424px){
    max-width: 414px;
    /* border: 1px solid red; */
    /* margin-left: 50px; */
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
      /* border: 1px solid red; */
    }
    .Top_Title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 412px) and (max-width: 413px){
    max-width: 412px;
    /* border: 1px solid red; */
    /* margin-left: 40px; */
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    max-width: 390px;
    /* border: 1px solid red; */
    /* margin-left: 36px; */
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
    }    
    .Top_Title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    max-width: 375px;
    /* border: 1px solid red; */
    /* margin-left: 20px; */
    .home_wrapper {
      padding: 0 20px;
    }
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
      /* border: 1px solid red; */
    }
    .Top_Title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 360px;
    /* border: 1px solid red; */
    /* margin-left: 20px; */
    .home_wrapper {
      padding: 0 20px;
    }    
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
      /* border: 1px solid red; */
    }
    .Top_Title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    max-width: 320px;
    /* border: 1px solid red;    */
    /* margin-right: 20px; */
    .home_wrapper {
      padding: 0 20px;
    }
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
      /* border: 1px solid red; */
      /* margin-right: 30px; */
    }
    .Top_Title {
      font-size: 24px;
      text-align: center;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;
    /* border: 1px solid red; */
    /* margin-right: 20px; */
    .home_wrapper {
      padding: 0 20px;
    }
    .Instagram_Main {
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Title {
      font-size: 22px;
      text-align: center;
    }
  }
`;
