import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import {Button} from "antd";
import {Link} from "react-router-dom";
import img1 from "../../Assets/Images/Zigoo/Mask group (10).png"

const OfferBannerZigoo = ({offer_banner, content_all}) => {
  // console.log("offer---> banner", content_all);
  const api = new API();

  return (
    <OfferSectionAlign>
      <div className="wrapper">
        <div className="Offer_Section_Main">
          <div className="Offer_Section">
            <Link to={`/produscts`}>
              {/* <img src={img1} /> */}
              <img src={api.rootUrl1 + content_all?.banner_img?.image} />
            </Link>
          </div>
        </div>
      </div>
    </OfferSectionAlign>
  );
};

export default OfferBannerZigoo;

const OfferSectionAlign = styled.div`
  .wrapper {
    padding: 20px 0 0px 0;
    max-width: 100%;
  }

  img{
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      padding: 0;
    }
  }
`;
