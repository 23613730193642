import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Rate, Tooltip, Input, Form, message } from "antd";
import logo from "../../Assets/Images/Zigoo/Zigoo-3508X2481.png";
import { base_url } from "../../config";
import { useSelector } from "react-redux";
import API from "../../ApiService/ApiService";

const ZigooAddress = ({ content_all }) => {
  // console.log("ZigooAddress--->", content_all);
  const content_all1 = {
    smile_footer_logo: {
      image: logo,
    },
    smile_footer_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    smile_footer_contact: "1800 123 0123",
    timing: "(Monday to saturday 9.30 am to 7 pm,Sunday 9.30 am to 12pm)",
  };

  const [form] = Form.useForm();
  const api = new API();

  const onFinish = (values) => {
    api.createCommon("subscriber", values).then((res) => {
      if (res?.status == 201) {
        form.resetFields();
        message.success(res?.data?.message);
      } else {
        message.error("Something went wrong");
      }
    });
  };

  return (
    <Section>
      <div className="Footer_Top_Main">
        <div className="Footer_Top1">
          <div className="Logo_Image">
            <p
              style={{
                margin: "0",
                fontSize: "3rem",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              ZIGOO
            </p>
            {/* <Link to="/">
                  <img src={base_url + content_all?.logo?.image} alt="logo" />
                  </Link> */}
          </div>
          <div className="content">
            {/* <p style={{ margin: "0" }}>              
              {content_all?.desc}
            </p> */}
          </div>
          {/* <div className="Online_Card">
                  <img src={footerImage} alt="" />
                </div> */}
        </div>
        <div className="Footer_Top2">
          <p style={{ display: "flex", margin: "0" }}>
            <svg
              className="svg_Mobile"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.7388 16.4136C21.5716 17.6839 20.9477 18.85 19.9837 19.6939C19.0196 20.5379 17.7813 21.0021 16.5 20.9998C9.05626 20.9998 3.00001 14.9436 3.00001 7.49981C2.99771 6.21852 3.4619 4.98021 4.30588 4.01615C5.14986 3.05209 6.31592 2.42822 7.58626 2.26106C7.9075 2.22184 8.2328 2.28756 8.51362 2.44841C8.79444 2.60926 9.0157 2.85662 9.14438 3.15356L11.1244 7.57387V7.58512C11.2229 7.81242 11.2636 8.06058 11.2428 8.30744C11.222 8.5543 11.1404 8.79217 11.0053 8.99981C10.9884 9.02512 10.9706 9.04856 10.9519 9.072L9.00001 11.3857C9.7022 12.8126 11.1947 14.292 12.6403 14.9961L14.9222 13.0545C14.9446 13.0357 14.9681 13.0181 14.9925 13.002C15.2 12.8636 15.4387 12.7792 15.687 12.7562C15.9353 12.7333 16.1854 12.7727 16.4147 12.8707L16.4269 12.8764L20.8434 14.8554C21.1409 14.9837 21.3889 15.2047 21.5503 15.4856C21.7116 15.7664 21.7778 16.092 21.7388 16.4136Z"
                fill="#FFFFFF"
              />
            </svg>
            <p className="Mobile_Number" style={{ margin: "0" }}>
              "For any help and queries, you may call us at"
            </p>
          </p>
          <p className="Mobile_Number" >
            {/* {company?.contact_number}, {company?.mobile_number} */}
            {content_all?.phoneno}
          </p>
          <p>
            <span className="Mobile_Number">
              {/* {company?.company_addresss} - {company?.pin_code} */}
              {content_all?.timing}
            </span>
          </p>
        </div>
        <div className="Footer_Top3">
          <p className="mail" style={{ color: "#fff", margin: "0" }}>
            <svg
              className="svg_Mobile"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.4163 8.37562L12.4163 2.37562C12.293 2.29339 12.1482 2.24951 12 2.24951C11.8518 2.24951 11.707 2.29339 11.5837 2.37562L2.58375 8.37562C2.48101 8.44417 2.39679 8.53703 2.33857 8.64595C2.28034 8.75488 2.24992 8.87649 2.25 9V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V9C21.7501 8.87649 21.7197 8.75488 21.6614 8.64595C21.6032 8.53703 21.519 8.44417 21.4163 8.37562ZM3.75 18.75V10.4559L9.92813 14.8622C10.0553 14.953 10.2077 15.0019 10.3641 15.0019H13.6359C13.7923 15.0019 13.9447 14.953 14.0719 14.8622L20.25 10.4559V18.75H3.75Z"
                fill="#FFFFFF"
              />
            </svg>
            Get Special discount on your inbox
          </p>
          <Form
            className="input_container"
            form={form}
            onFinish={onFinish}
            style={{ paddingBottom: "0px" }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  pattern: new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ),
                  required: true,
                  message: "Please enter a valid email",
                },
              ]}
              style={{ margin: "0", backgroundColor: "transparent", border: "none", outline: "none" }}
            >
              <Input
                // placeholder="Enter your email"
                className="input"
                style={{
                  borderRadius: "2px 0 0 2px",
                  // border: "1px solid #000",
                  // margin: "0",
                  border: "none",
                  color: "#fff",
                  backgroundColor: "transparent",
                  outline: "none",
                  borderBottom: "1px solid #fff",
                }}
              />
            </Form.Item>
            <Form.Item
              style={{ 
                // border: "1px solid #fff",
                display: "flex",
                justifyContent: "flex-end",
               }}
            >
              <Button 
              className="button" 
              size="small" 
              htmlType="submit" 
              style={{ 
                backgroundColor: "transparent", 
                color: "#fff", 
                }}>
                Submit{" "}
              </Button>
            </Form.Item>
          </Form>
          {/* <p className="subscription" style={{ color: "#fff", margin: "0" }}>
            By clicking the SUBSCRIBE button, you are agreeing to our{" "}
            <u>
              <Link
                to="/privacy-policy"
                style={{ textDecoration: "none", color: "#000" }}
              >
                Privacy & Cookie Policy
              </Link>
            </u>
          </p> */}
          {/* <Input></Input>    
                 <Button className="Button" style={{ marginTop: "10px"}}>
                    Submit             
                  </Button>            */}
        </div>
      </div>
    </Section>
  );
};

export default ZigooAddress;

const Section = styled.div`
  background-color: #038175;
  margin-top: 20px;
  .Footer_Top_Main {
    padding: 40px 0 40px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Footer_Top1 {
    width: 25%;
  }

  .Footer_Top2 {
    width: 30%;
  }

  .Footer_Top3 {
    width: 30%;
    padding: 0 20px;
  }

  .Logo_Image {
    /* border: 1px solid #fff; */
    /* width: 90px; */
    /* height:80%; */
    flex-shrink: 0;
    /* background-color: #fff; */
  }

  .Footer_Top1 .content {
    /* width: 345px; */
    /* height: 127px; */
    flex-shrink: 0;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Top1 .Online_Card {
    /* width: 305.504px; */
    height: 32px;
    flex-shrink: 0;
  }

  .Footer_Top2_Title {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
  }

  .Footer_Top2 .Mobile_Number {
    padding-left: 5px;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Top2 .svg_Mobile {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin: 1% 0 0 0;
  }

  .Footer_Top3 .mail {
    padding-left: 5px;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

  .Footer_Top3 .svg_Mobile {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  @media screen and (max-width: 1025px) {
    .Footer_Top_Main {
      padding: 20px 0 20px 0;
    }
    .Footer_Top1 {
      width: 35%;
    }

    .Footer_Top2 {
      width: 25%;
    }

    .Footer_Top3 {
      width: 30%;
    }
  }

  @media screen and (max-width: 992px) {
    .Footer_Top_Main {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .Footer_Top_Main {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .Footer_Top1 {
      width: 100%;
      padding: 0 20px;
    }

    .Footer_Top2 {
      width: 100%;
      padding: 0 20px;
    }

    .Footer_Top3 {
      width: 100%;
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 576px) {
    .Footer_Top1 {
      width: 100%;      
    }

    .Footer_Top2 {
      width: 100%;      
    }

    .Footer_Top3 {
      width: 100%;      
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
    .Footer_Top1 {
      width: 90%;      
    }

    .Footer_Top2 {
      width: 90%;      
    }

    .Footer_Top3 {
      width: 95%;      
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
    .Footer_Top1 {
      width: 90%;      
    }

    .Footer_Top2 {
      width: 90%;      
    }

    .Footer_Top3 {
      width: 95%;      
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 912px) and (orientation: portrait) {
    .Footer_Top1 {
      width: 90%;      
    }

    .Footer_Top2 {
      width: 90%;      
    }

    .Footer_Top3 {
      width: 95%;      
    }
  }


/* @media only screen and (min-width: 821px) and (max-width: 1180px) and (orientation: landscape) {
  .Footer_Top1 {
      width: 100%;      
    }

    .Footer_Top2 {
      width: 100%;      
    }

    .Footer_Top3 {
      width: 100%;      
    }
} */
`;
