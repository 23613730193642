import React from "react";
import styled from "styled-components";
import {Button, Rate , Tooltip} from "antd";
import API from "../../ApiService/ApiService";
import image1 from "../../Assets/Images/Zigoo/hairdresser-barber-shop-styling-hair-client 1.png";
import image2 from "../../Assets/Images/Zigoo/Rectangle 474.png";
import image3 from "../../Assets/Images/Zigoo/Rectangle 475.png";
import '../../Assets/Fonts/fonts.css';

const ReadingBeauty = ({content_all}) => {
  // console.log("ReadingBeautycontent_all", content_all);
  const api = new API();

  const why_us = {
    section_title: content_all?.onewear_blog_main_title,
    whyus_id: [
      {
        id: 1,
        image: content_all?.banner_img_1?.image,
        card_title: content_all?.banner_title_1,
        card_description: content_all?.banner_desc_1,
        // date_desc: content_all?.onewear_blog_dt1,
      },
      {
        id: 2,
        image: content_all?.banner_img_2?.image,
        card_title: content_all?.banner_title_2,
        card_description: content_all?.banner_desc_2,
        // date_desc: content_all?.onewear_blog_dt2,
      },
      {
        id: 3,
        image: content_all?.banner_img_3?.image,
        card_title: content_all?.banner_title_3,
        card_description: content_all?.banner_desc_3,
        // date_desc: content_all?.onewear_blog_dt3,
      },
    ],
  };
  const why_us1 = {
    section_title: "Exploring the reading beauty",
    whyus_id: [
      {
        id: 1,
        image: image1,
        card_title: "Explore the trends",
        card_description: "Welcome to Essential Looks 2:2023. Essential Looks is a trend-based educational tool which curates the latest movements in hair and fashion.",
      },
      {
        id: 2,
        image: image2,
        card_title: "Explore the trends",
        card_description: "Welcome to Essential Looks 2:2023. Essential Looks is a trend-based educational tool which curates the latest movements in hair and fashion.",
      },
      {
        id: 3,
        image: image3,
        card_title: "Explore the trends",
        card_description: "Welcome to Essential Looks 2:2023. Essential Looks is a trend-based educational tool which curates the latest movements in hair and fashion.",
      },
    ],
  };

  return (
    <WhyUsSectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{why_us1?.section_title}</p>
          <Button className="Button" onClick={() => window.location.href = "/products"}>
              View all{" "}             
            </Button>
        </div>
        <div className="WhyUs_Section_Main">
          <div className="WhyUs_Section">
            {why_us?.whyus_id?.map((item, index) => (
              <div key={index} className="WhyUs_Section_Box">
                <div className="WhyUs_Section_Box_Image">
                  <img src={api.rootUrl1 + item?.image} />
                  {/* <img src={item?.image} /> */}
                </div>
                <div className="WhyUs_Section_Box_Title">
                  <div>{item?.card_title}</div>
                </div>                
                <div className="WhyUs_Section_Box_Description">
                  <div>{item?.card_description}</div>
                </div>                
              </div>
            ))}
          </div>
        </div>
      </div>
    </WhyUsSectionAlign>
  );
};

export default ReadingBeauty;

const WhyUsSectionAlign = styled.div`
  margin: 100px 60px  40px 60px;
  .home_wrapper {
    max-width: 90%;
    flex-shrink: 0;
    background: #ffffff;
    margin: auto;
  }
  .Top_Title {
    /* text-align: center; */
    /* padding: 30px 0 30px 0; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Top_Title .Title {
    color: #000;
    font-family: "Josefin Sans", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
    padding: 20px 0;
  }

  .Top_Title .Span_Title {
    padding-left: 10px;
    color: #0174be;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .WhyUs_Section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
  }

  .WhyUs_Section_Box_Image {
    height: 100%;
    width: 100%;
    margin-bottom: 30px;
    /* border: 1px solid red; */
    border-radius: 10px;
  }

  .WhyUs_Section_Box_Image img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: contain;
  }

  .WhyUs_Section_Box {
    /* width: 350px; */
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    /* height: 200px; */
  }

  .WhyUs_Section_Box_Title {
    /* padding-top: 10px; */

    /* text-align: center; */
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
  }

  .WhyUs_Section_Box_Description {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: #000;
    /* text-align: center; */
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-style: normal;
    /* font-weight: 300; */
    line-height: normal;
    margin-bottom: 20px;
  }

  .WhyUs_Section_Box_Description2 {
    color: #000;
    /* text-align: center; */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    /* font-weight: 300; */
    line-height: normal;
    margin-bottom: 10px;
  }

  .Button {
    color: #038175;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
    text-transform: uppercase;
    border: none;
    outline: none;
    background-color: #BADEDB;
    border-radius: 20px;
  }

  @media screen and (max-width: 1024px) {
    .WhyUs_Section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }

  @media screen and (max-width: 767px) {
    .WhyUs_Section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }

  @media screen and (max-width: 475px) {
    margin: 0px 10px  40px 10px;
    .WhyUs_Section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }
`;
