import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

const Productcosmetics = ({ content_all }) => {
  const [imageSet, setImageSet] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(2); // Default to 2 for desktop/tablet
  const scrollRef = useRef(null);
  const autoScrollInterval = useRef(null);

  useEffect(() => {
    const updateItemsPerView = () => {
      if (window.innerWidth <= 540) {
        setItemsPerView(1); // Show 1 item on mobile
      } else {
        setItemsPerView(2); // Show 2 items on tablets and larger screens
      }
    };

    updateItemsPerView(); // Set the initial value
    window.addEventListener('resize', updateItemsPerView); // Listen for window resize

    return () => window.removeEventListener('resize', updateItemsPerView);
  }, []);

  const scrollToCard = (index) => {
    const cardWidth = scrollRef.current.clientWidth / itemsPerView;
    const scrollAmount = cardWidth * index;
    scrollRef.current.scrollTo({
      left: scrollAmount,
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    if (imageSet > 0) {
      setImageSet((prevSet) => prevSet - 1);
      scrollToCard(imageSet - 1);
    }
  };

  const scrollRight = () => {
    if (imageSet < Math.ceil(content_all?.zigo_scroll_banner.length / itemsPerView) - 1) {
      setImageSet((prevSet) => prevSet + 1);
      scrollToCard(imageSet + 1);
    } else {
      // Reset to the first item after the last one
      setImageSet(0);
      scrollToCard(0);
    }
  };

  // Auto-scroll functionality
  useEffect(() => {
    // Clear any existing interval
    if (autoScrollInterval.current) {
      clearInterval(autoScrollInterval.current);
    }

    autoScrollInterval.current = setInterval(() => {
      scrollRight();
    }, 3000); // Change every 3 seconds

    // Clean up the interval when the component unmounts
    return () => {
      if (autoScrollInterval.current) {
        clearInterval(autoScrollInterval.current);
      }
    };
  }, [imageSet, itemsPerView]);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    position: 'relative',
    margin: '0 auto',
    paddingTop: '50px',
  };

  const scrollContainerStyle = {
    overflow: 'hidden',
    width: '90%',
    display: 'flex',
  };

  const cardStyle = {
    flex: `0 0 ${100 / itemsPerView}%`, // Adjust card width dynamically
    borderRadius: '10px',
    overflow: 'hidden',
    height: itemsPerView === 1 ? '30%' : '35%', // Adjust height for mobile and desktop
    // height: itemsPerView === 1 ? '200px' : '250px', // Adjust height for mobile and desktop
    aspectRatio: '16/9', // Ensures the card is always rectangular
    padding: '0 10px',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px',
  };

  const scrollButtonStyle = {
    backgroundColor: '#fff',
    color: 'black',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '50%',
    position: 'absolute',
    top: '60%',
    transform: 'translateY(-50%)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  };

  return (
    <div style={containerStyle}>
      <button
        style={{ ...scrollButtonStyle, left: '10px' }}
        onClick={scrollLeft}
        disabled={imageSet === 0}
      >
        ←
      </button>
      <div style={scrollContainerStyle} ref={scrollRef}>
        {content_all?.zigo_scroll_banner.map((item, index) => (
          <div key={index} style={cardStyle}>
            <Link to="/products">
              <img src={process.env.REACT_APP_BASE + item?.image} alt="" style={imageStyle} />
            </Link>
          </div>
        ))}
      </div>
      <button
        style={{ ...scrollButtonStyle, right: '10px' }}
        onClick={scrollRight}
        disabled={imageSet === Math.ceil(content_all?.zigo_scroll_banner.length / itemsPerView) - 1}
      >
        →
      </button>
    </div>
  );
};

export default Productcosmetics;
