import React from 'react'
import styled from "styled-components";
import Marquee from "react-fast-marquee";

const SingleTextSection = ({content_all}) => {
  // console.log("SingleTextSection--->", content_all);
// const content_all1 = {
//     singleTextSection_text: "We are best manufacure of shaving cream",
// }
  return (
    <BannerAlign>
        <div className="Offer_Section">
            {content_all?.zigoo_txt}<a href="/products">Shop Now</a>
        </div>
    </BannerAlign>
  )
}

export default SingleTextSection;

const BannerAlign = styled.div`
    /* padding-bottom: 80px; */
    .Offer_Section {
    /* padding-top: 5px; */
    width: 100%;
    /* height: 97px; */
    flex-shrink: 0;
    background: #94DD8B;
    color: #000;
    /* display: grid; */
    /* place-items: center; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-template-rows: 1fr; */
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
    font-size: 0.8rem;
    font-weight: 400;

    a {
        text-decoration: underline;
        color: #000;
        font-family: "Inter", sans-serif;
    }
  } 

  @media screen and (max-width: 768px) {
    .Offer_Section {
      font-size: 0.7rem;
      gap: 0.5rem;
    }

    .Offer_Section a {
      font-size: 0.7rem;
    }      
  }

  @media screen and (max-width: 480px) {
    .Offer_Section {
      font-size: 0.6rem;
      gap: 0.5rem;
    }

    .Offer_Section a {
      font-size: 0.6rem;
    }
  }

  @media screen and (max-width: 360px) {
    .Offer_Section {
      font-size: 0.5rem;
      gap: 0.5rem;
    }

    .Offer_Section a {
      font-size: 0.5rem;
    }
  }

  @media screen and (max-width: 320px) {
    .Offer_Section {
      font-size: 0.4rem;
      gap: 0.5rem;
    }

    .Offer_Section a {
      font-size: 0.4rem;
    }
  }  
`;