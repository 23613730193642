import React from "react";
import styled from "styled-components";
import whyUsSec from "../../Assets/Images/why_us_sec_2.png";
import ourPromise from "../../Assets/Images/our_promise.png";
import ourValues from "../../Assets/Images/our_values.png";
import competitiveProgramming from "../../Assets/Images/competitive_pricing.png";
import trustedSeller from "../../Assets/Images/trusted_seller.png";
import { Carousel, Button } from "antd";
import API from "../../ApiService/ApiService";
import bgImg from "../../Assets/Images/Zigoo/top-view-composition-cosmetics-with-copy-space 1.png";
import Img1 from "../../Assets/Images/Zigoo/Group.png";

const BeautyProducts = ({ why_us, content_all }) => {

  // console.log("content_allwhy_us", content_all);
  const api = new API();
  // console.log("why_us", why_us);
  const content_all1 = {
    gowri_feature_title: "Beauty products that really works",
    gowri_feature_description: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
  }
  const why_us_array = [
    // {
    //   id: 1,
    //   image: Img1,
    //   card_title: "Shining skin",
    //   card_description: "Sparked by a love for beauty",
    // },
    {
      id: 1,
      image: content_all?.banner_image_1?.image,
      card_title: content_all?.banner_main_title_1,
      card_description: content_all?.banner_sub_title_1,
    },
    // {
    //   id: 2,
    //   image: Img1,
    //   card_title: "Shining skin",
    //   card_description: "Sparked by a love for beauty",
    // },
    {
      id: 2,
      image: content_all?.banner_image_2?.image,
      card_title: content_all?.banner_main_title_2,
      card_description: content_all?.banner_sub_title_2,
    },
    // {
    //   id: 3,
    //   image: Img1,
    //   card_title: "Shining skin",
    //   card_description: "Sparked by a love for beauty",
    // },
    {
      id: 3,
      image: content_all?.banner_image_3?.image,
      card_title: content_all?.banner_main_title_3,
      card_description: content_all?.banner_sub_title_3,
    },
    // {
    //   id: 4,
    //   image: Img1,
    //   card_title: "Shining skin",
    //   card_description: "Sparked by a love for beauty",
    // },
    {
      id: 4,
      image: content_all?.banner_image_4?.image,
      card_title: content_all?.banner_main_title_4,
      card_description: content_all?.banner_sub_title_4,
    },
  ]
  // console.log("image", api.rootUrl1 + content_all?.background_image?.image);
  return (
    <Section style={{
      backgroundImage: `url(${api.rootUrl1.replace(/\/$/, '') + '/' + content_all?.background_image?.image.replace(/\\/g, '/')})`,
      backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          // height: '300px',
        }}> 
      <div className="main_container"  >        
        <div className="right_side">
          <h2>{content_all?.title_main}</h2>
          <p className="desc">{content_all?.title_sub}</p>
          <div className="grid_container">
            {why_us_array?.map((item) => (
              <div className="why_us_grid_container">
                <div className="icon">
                  {/* <img src={item?.image} alt="trusted seller" /> */}
                  <img src={api.rootUrl1 + item?.image} alt="trusted seller" />
                </div>
                <div className="why_us_grid_container_details">
                  <p className="heading">{item?.card_title}</p>
                  <p className="card_desc">
                   {item?.card_description}
                  </p>
                </div>
              </div>
            ))}           
          </div>
          <Button
                    className="custom-button"
                    ghost
                    style={{
                      borderRadius: "0.3em",
                      fontWeight: "bold",
                      border: "1px solid #000",
                      backgroundColor: "#fff",
                      color: "#000",
                    }}
                    onClick={() => (window.location.href = `/products`)}
                  >
                    Shop now
                  </Button>
        </div>
      </div>
    </Section>
  );
};

export default BeautyProducts;

const Section = styled.section`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* padding-top: 100px; */
  /* border: 1px solid red; */

  .main_container {
    margin: 60px 25px;
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    /* gap: 30px;     */
    width: 50%;

    .right_side {
        /* margin-left: 25px; */
      padding: 80px 15px 50px 15%;
      h2 {
        color: #000;
        font-family: "Josefin Sans", sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 25px;
      }
      .desc {
        margin-bottom: 25px;
      }
      p {
        color: #898787;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 32px */
      }

      .grid_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px 0;

        .why_us_grid_container {
          display: flex;

          .icon {
            width: 35px;
          }

          .why_us_grid_container_details {
            margin-left: 15px;

            p {
              color: #898787;
              font-family: Brevia;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }

            .heading {
              color: #000;
              font-family: "Josefin Sans", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
              margin-bottom: 8px;
            }

            .card_desc{
              width: 180px;
              font-family: "Inter", sans-serif;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .main_container {
      margin: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .main_container {
      width: 100%;
      margin: 0;
      grid-template-columns: repeat(1, 1fr);
      .left_side {
        justify-self: center;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .right_side {
      padding: 80px 15px 50px 15px !important;
      h2 {
        font-size: 30px !important;
        margin-bottom: 10px !important;
      }
      .grid_container {
        gap: 10px !important;
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }
`;
