import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Form, Button, message } from "antd";
// import image1 from "../../Assets/Images/GirlsHub Template/f-1.png";
// import image2 from "../../Assets/Images/GirlsHub Template/f-2.png";
// import image3 from "../../Assets/Images/GirlsHub Template/f-3.png";
// import image4 from "../../Assets/Images/GirlsHub Template/f-4.png";
// import image5 from "../../Assets/Images/GirlsHub Template/f-5.png";
// import image6 from "../../Assets/Images/GirlsHub Template/f-6.png";
// import image7 from "../../Assets/Images/GirlsHub Template/f-7.png";
// import image8 from "../../Assets/Images/GirlsHub Template/f-8.png";
// import image9 from "../../Assets/Images/GirlsHub Template/f-9.png";
// import image10 from "../../Assets/Images/GirlsHub Template/f-10.png";
// import image11 from "../../Assets/Images/GirlsHub Template/f-11.png";
// import image12 from "../../Assets/Images/GirlsHub Template/f-12.png";
// import image13 from "../../Assets/Images/GirlsHub Template/f-13.png";
// import image14 from "../../Assets/Images/GirlsHub Template/f-14.png";
import ecdigiLogo from "../../Assets/Images/ecdigi.png";
import { Link } from "react-router-dom";
import { base_url } from "../../config";
import API from "../../ApiService/ApiService";
import { useSelector } from 'react-redux';
const Footer14 = ({ company, social, home_custom, content_all }) => {
  // console.log("company=====>",company)
  // console.log("home_custom=====>",home_custom)
  // console.log("content_all==>Footer14", content_all)

  const category = useSelector((state) => state?.category?.category?.data);

  // console.log("category", category);
  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  const ParentCategory = category?.filter((e) => e?.category_id == null);

  // console.log("ParentCategory", ParentCategory);

  // const Icons = [
  //   {
  //     image: image1,
  //   },
  //   {
  //     image: image2,
  //   },
  //   {
  //     image: image3,
  //   },
  //   {
  //     image: image4,
  //   },
  //   {
  //     image: image5,
  //   },
  //   {
  //     image: image6,
  //   },
  //   {
  //     image: image7,
  //   },
  //   {
  //     image: image8,
  //   },
  //   {
  //     image: image9,
  //   },
  //   {
  //     image: image10,
  //   },
  //   {
  //     image: image11,
  //   },
  //   {
  //     image: image12,
  //   },
  //   {
  //     image: image13,
  //   },
  //   {
  //     image: image14,
  //   },
  // ];
  const [form] = Form.useForm();
  const api = new API();

  const onFinish = (values) => {
    api.createCommon("subscriber", values).then((res) => {
      if (res?.status == 201) {
        form.resetFields();
        message.success(res?.data?.message);
      } else {
        message.error("Something went wrong");
      }
    });
  };

  return (
    <Section>
      <div className="container">
        <div className="left_footer">
        <div className="nav_links_container">
            <h3>Category</h3>
            <ul className="nav_links">
              {
                ParentCategory?.map((item, index) => (
                  // console.log("item", item),
                  <li key={index}>
                    <Link
                      to={`/products?catName=${item?.name}`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      {item?.name}
                    </Link>
                  </li>
                ))
                // (<li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Maternity Leggins</Link></li>)
              }
            </ul>
          </div>
          <div className="nav_links_container">
            <h3>Quick Links</h3>
            <ul className="nav_links">
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/my-profile"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  My Account
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/my-order"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  My Orders
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/cart"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  My Shopping Bag
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/wish-list"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  My Wishlist
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/delivery-address"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Delivery Address
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/change-password"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Change Password
                </Link>
              </li>             
              <li>
                <Link
                  to="/terms-and-condition"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Terms & Condition
                </Link>
              </li>                         

              {/* <li>Shipping Policy</li> */}
            </ul>
          </div>
          <div className="nav_links_container">
            <h3>Customer Service</h3>
            <ul className="nav_links">
              <li>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  About Us
                </Link>
              </li>
              {/* <li>FAQ’s</li> */}  
              <li>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Contact Us
                </Link>
              </li>            
              <li>
                <Link
                  to="/delivery-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Delivery Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/cancellation-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Cancellation Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/return-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Return Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/refund-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Privacy Policy
                </Link>
              </li>   
            </ul>
          </div>          
          <div className="nav_links_container">
            <h3>Social</h3>
            <ul className="nav_links">
              {content_all?.zigoosocialmedia.map((item, index) => (
                <li>
                <a
                  href={`${item?.smedia_link?.startsWith('http') ? item.smedia_link : `https://${item.smedia_link}`}`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#000" }}
                  rel="noopener noreferrer"
                >
                  {/* Instagram */}
                  {item?.smedia_name}
                </a>
              </li>
              ))}      
            </ul>
          </div>          
        </div>       
      </div>      
      <div className="Footer_Bottom_bar">
        {/* <Link to="https://www.ecdigi.com/" target="_blank" style={{ textDecoration: 'none' , color: '#000'}}> */}
        <div className="Left_Bar">
        Copyright @ 2024{" "}
          {/* <Link
            to="https://www.ecdigi.com/"
            target="_blank"
            style={{ textDecoration: "none", color: "white", height: "100%", display: "flex", alignItems: "center" }}
          >
            <img 
            src={ecdigiLogo} 
            alt="ecDigi logo" 
            />
          </Link> */}
        </div>
        <div className="Right_Bar">
           {/* Developed by&nbsp;{" "} */}
           Copyright @ 2024{" "}
          <Link
            to="https://www.ecdigi.com/"
            target="_blank"
            style={{ textDecoration: "none", color: "white", height: "100%", display: "flex", alignItems: "center" }}
          >
            {/* ecDigi */}
            {/* <img 
            src={ecdigiLogo} 
            alt="ecDigi logo" 
            /> */}
            {/* {content_all?.designed_by} */}
          </Link>
        </div>
        {/* </Link> */}
        {/* <div className="Right_Bar">Designed by Blazon</div> */}
      </div>
    </Section>
  );
};

export default Footer14;

const Section = styled.section`
  background-color: #fbfbfb;
  padding-top: 100px;
  /* padding-bottom: 50px; */

  .container {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    max-width: 100%;
    width: 90%;
    margin: auto;
    .left_footer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 60%;
      margin: auto;

      .nav_links_container {
        h3 {
          color: #000;
          font-family: "Inter", sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 26.4px */
        }
        ul {
          margin: 0;
          padding: 0;

          li {
            font-size: 14px;
            margin: 7px 0;
          }
        }
      }
    }
    .right_footer {
      width: 40%;

      .input_container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;

        input {
          width: 60%;
          padding-left: 10px;
        }

        button {
          background: var(--grey-grey-800, #1f2937);
          display: flex;
          padding: 7px 30px;
          align-items: flex-start;
          gap: 10px;
          color: white;
          border: none;
        }
      }

      .title {
        color: #000;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .subscription {
        color: #000;
        font-family: "Inter", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
      }
    }
  }
  .bottom_footer {
    padding-top: 25px;

    .rights {
    }
  }

  .footer_bottom_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
  }

  .footer_bottom_Main .footer_bottom_right {
    width: 30%;
  }

  .footer_bottom_Main .footer_bottom_right .icons_Main {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
  }

  .footer_bottom_Main .footer_bottom_left {
    width: 50%;
  }

  .footer_bottom_Main .footer_bottom_left .Bottom_List {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .footer_bottom_Main .footer_bottom_left .Bottom_List li {
    color: #000;

    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    text-decoration-line: underline;
  }

  .footer_bottom_Main .footer_bottom_left .rights {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    padding-left: 20px;
  }

  .Footer_Bottom_bar {
    width: 100%;
    height: 44.441px;
    flex-shrink: 0;
    background: #038175;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* justify-content: center; */
    color: #ffff;
    padding: 0 100px;
    margin-top: 40px;
  }

  .Footer_Bottom_bar .Left_Bar {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
  display: none;

    img {
  max-width: 100%; /* Ensures the image scales within the container */
  max-height: 50%; /* Ensures the image does not exceed the container’s height */
  object-fit: contain; /* Maintains aspect ratio and avoids distortion */
}
  }

  
  .Footer_Bottom_bar .Right_Bar {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 100%;
    width: 50%;
    display: flex;
  align-items: center;
  justify-content: flex-end;

    img {
  max-width: 100%; /* Ensures the image scales within the container */
  max-height: 50%; /* Ensures the image does not exceed the container’s height */
  object-fit: contain; /* Maintains aspect ratio and avoids distortion */
}
  }

  @media screen and (min-width: 1441px) and (max-width: 2560px) {
    .container {
      width: 90%;
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(4, 1fr) !important;
    }   
    
  }

  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .container {
      width: 90%;
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(4, 1fr) !important;
    }   
    
  }

  @media screen and (min-width: 1025px) and (max-width: 1199px) {
    .container {
      width: 90%;
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }   
    
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    .container {
      display: block;
      width: 90%;
      /* border: 1px solid red; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 5%;
    }    
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 5%;
    }    
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(3, 1fr) !important;
      /* margin-left: 20px; */
      gap: 5%;
    }    
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(2, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
    }   
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }   
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }    
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }    
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }    
    .Footer_Bottom_bar {
      padding: 0px 30px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      padding: 0 5%;
      gap: 2%;
    }   
    .Footer_Bottom_bar {
      padding: 0px 30px;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }   
    .Footer_Bottom_bar {
      padding: 0px 30px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* border: 1px solid red; */
      /* text-align: center; */
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }    
    .Footer_Bottom_bar {
      /* border: 1px solid red; */
      padding: 0 30px;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    .container {
      display: block;
      width: 90%;
      /* width: 100%; */
    }
    .left_footer {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* margin-left: 20px; */
      gap: 2%;
      padding: 0 5%;
    }    
    .Footer_Bottom_bar {
      /* border: 1px solid red; */
      padding: 0 30px;
    }    
  }
`;
