import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import img1 from "../../Assets/Images/Zigoo/kisspng-cream-lotion-sunscreen-cosmetics-skin-scrab-5b326921dfc8d1.3276494415300303699166 3.png";
import API from "../../ApiService/ApiService";

const FP14 = ({ content_all }) => {
  const api = new API();
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);

  const getItemsPerPage = () => {
    if (window.innerWidth >= 912) return 5;
    if (window.innerWidth >= 768) return 4;
    if (window.innerWidth >= 525) return 3;
    return 1;
  };

  const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(getItemsPerPage());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    if (currentIndex + itemsPerPage < Featured_Products.length) {
      setCurrentIndex(currentIndex + 1); // Scroll one item at a time
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1); // Scroll one item at a time
    }
  };

  const Featured_Products = content_all?.all_pro_zigo || [];

  return (
    <FavouritesAlign>
      <div className="home_wrapper">
        <div className="Top_Section">
          <div className="Left">{content_all?.fp_title || "All You Needed"}</div>
          <div className="Right">
            <Link to={"/products"}>
              <Button className="Button">View all</Button>
            </Link>
          </div>
        </div>
        <div className="Fav_Product_Main">
          <LeftOutlined
            className="WhyUs_Section_Left"
            onClick={handlePrev}
            style={{ cursor: currentIndex === 0 ? "not-allowed" : "pointer" }}
            disabled={currentIndex === 0}
          />
          {Featured_Products?.slice(currentIndex, currentIndex + itemsPerPage).map((item, index) => (
            <Tooltip placement="top" title={"View More"} key={index}>
              <div
                className="Fav_Product_Box"
                onClick={() => navigate(`/product/${item?.value}`)}
              >
                <div className="Fav_Product_Image">
                  <img src={api?.rootUrl1 + item?.image} alt="" />
                </div>
                <div className="Fav_Product_Title">
                  <span>{item?.label || "Product Name"}</span>
                </div>
                {/* {item?.offer && (
                  <div className="Top_offer">
                    <span className="Offer_Badge">{item?.offer}</span>
                  </div>
                )} */}
              </div>
            </Tooltip>
          ))}
          <RightOutlined
            onClick={handleNext}
            style={{
              cursor: currentIndex + itemsPerPage >= Featured_Products.length ? "not-allowed" : "pointer",
            }}
            disabled={currentIndex + itemsPerPage >= Featured_Products.length}
          />
        </div>
      </div>
    </FavouritesAlign>
  );
};

export default FP14;

const FavouritesAlign = styled.div`
margin-top: 80px;
margin-bottom: 80px;
  .home_wrapper {
    padding: 0px 5%;
    max-width: 90%;
    margin: auto;
  }  

  .Top_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3% 0;
  }

  .Top_Section .Left {
    color: #000;
    font-family: "Josefin Sans", sans-serif;
    font-size: 30px; /* Responsive font size */
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .Top_Section .Right .Button {
    color: #038175;
    font-family: "Inter", sans-serif;
    font-size: 14px; /* Responsive font size */
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    border: none;
    outline: none;
    background-color: #BADEDB;
    border-radius: 20px;
  }

  .Fav_Product_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
    /* flex-wrap: wrap; */
    width: 100%;
  }

  .Fav_Product_Main .Fav_Product_Box {
    width: 20%; /* Responsive width of the box */
    line-height: 30px;
    height: 40vh !important;
  }

  .Fav_Product_Image {
    width: 100%; /* Full width of the box */
    height: 80%; /* Adjust height as a percentage of the box */
    display: flex;
    background-color: #F2F2F2; /* Background color for the image container */
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
  }

  .Fav_Product_Image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image fits properly */
  }

  .Fav_Product_Main .Fav_Product_Title {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 14px; /* Responsive font size */
    font-style: normal;
    font-weight: 600;
    padding-top: 2%;
    /* text-align: center; */
  }

  .Top_offer {
    width: 20%; /* Responsive width of the offer badge */
    max-width: 100px; /* Max width for larger screens */
    border-radius: 24px;
    background: #fff;
    position: relative;
    top: -10%;
    display: grid;
    place-items: center;
    height: 20%;
    max-height: 30px; /* Max height for larger screens */
  }

  .Top_offer .Offer_Badge {
    color: #2f4076;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 12px; /* Responsive font size */
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1199px) {
    max-width: 1024px;
    .Fav_Product_Main {
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }

  @media screen and (max-width: 991px) {
    .Fav_Product_Main {
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 10px;
    }    
  }

  @media screen and (max-width: 911px) {
    .Fav_Product_Main {
      flex-wrap: nowrap;
      overflow-x: auto;
      gap: 10px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 834px) and (orientation: portrait) {
    .Fav_Product_Main .Fav_Product_Box {    
    height: 16vh;
    }
  }

  @media only screen and (min-width: 821px) and (max-width: 1194px) and (orientation: landscape) {
    .Fav_Product_Main .Fav_Product_Box {    
    height: 22vh;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .Fav_Product_Main .Fav_Product_Box {    
    height: 16vh;
    }
  }

/* Landscape mode for 12.9-inch iPad Pro (1366px) */
  @media only screen and (min-width: 1025px) and (max-width: 1366px) and (orientation: landscape) {
  .Fav_Product_Main .Fav_Product_Box {    
    height: 22vh;
    }
  }

  @media screen and (max-width: 767px) {
    /* .Top_Section .Right .Button {
      font-size: 1.2vw; 
    }
    .Top_Section .Left {
      font-size: 2.5vw; 
    } */

      .Fav_Product_Main{
        gap: 10px;
      }
  }

  @media only screen and (max-width: 540px) and (orientation: portrait) {
    .Fav_Product_Main .Fav_Product_Box {    
    height: 22vh;
    }
  }

/* Landscape mode for a single screen of Surface Duo (720px) */
  @media only screen and (max-width: 720px) and (orientation: landscape) {
    .Fav_Product_Main .Fav_Product_Box {    
    height: 35vh;
    }
  }

  @media (max-width: 525px) {
    .Fav_Product_Box {
      width: 80% !important;
      height: 50vh !important;
    }
    .home_wrapper {
      padding: 0 0px !important;
    }

    .Fav_Product_Main{
        gap: 10px;
      }
    /* .Top_Section .Right .Button {
      font-size: 1.2vw; 
    }
    .Top_Section .Left {
      font-size: 2.5vw; 
    } */
  }
`;

