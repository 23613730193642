import React, { useState } from "react";
import styled from "styled-components";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import { useSelector } from "react-redux";
import IMG1 from "../../Assets/Images/Zigoo/Rectangle 666.png";
import IMG2 from "../../Assets/Images/Zigoo/Rectangle 667.png";
import IMG3 from "../../Assets/Images/Zigoo/Rectangle 668.png";
import IMG4 from "../../Assets/Images/Zigoo/pink-blow-dryer-with-word-free-it 1.png";

let user = "";

const ShopByCategory = ({ content_all }) => {
  const getUser = localStorage.getItem("user");
  const api = new API();
  const navigate = useNavigate();

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const Favorite_Products = [];

  if (content_all && content_all?.zigo_categories) {
    content_all?.zigo_categories.forEach((item) => {
      if (
        !Favorite_Products.some(
          (favoriteItem) => favoriteItem?.value === item?.value
        )
      ) {
        Favorite_Products.push(item);
      }
    });
  }

  const category = useSelector((state) => state?.category?.category?.data);

  const content_all1 = {
    onewear_product_title: "Shop By Category",
  };

  const Favorite_Products1 = [
    {
      label: "Zigoo",
      value: "zigoo",
      image: IMG1,
    },
    // Add other products here
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Adjust the number of items displayed based on the window width
  const getItemsPerPage = () => {
    if (window.innerWidth >= 912) return 4;
    if (window.innerWidth >= 768) return 3;
    if (window.innerWidth >= 525) return 2;
    return 1;
  };

  const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());

  const handleResize = () => {
    setItemsPerPage(getItemsPerPage());
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    if (currentIndex + itemsPerPage < Favorite_Products.length) {
      setCurrentIndex(currentIndex + 1); // Scroll one item at a time
    }
  };

  const handlePrev = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1); // Scroll one item at a time
    }
  };

  return (
    <WhyUsSectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{content_all1?.onewear_product_title}</p>
        </div>
        <div className="WhyUs_Section_Main">
          <LeftOutlined
            className="WhyUs_Section_Left"
            onClick={handlePrev}
            style={{ cursor: currentIndex === 0 ? "not-allowed" : "pointer" }}
            disabled={currentIndex === 0}
          />
          {Favorite_Products?.slice(
            currentIndex,
            currentIndex + itemsPerPage
          )?.map((item, index) => (
            <div key={index} className="WhyUs_Section_Box">
              <div className="WhyUs_Section_Box_Image">
                <Link to={`/products?catName=${item?.label}`}>
                  <img src={api?.rootUrl1 + item?.image} alt={item?.label} />
                </Link>
              </div>
              <div className="WhyUs_Section_Box_Title">
                <div>{item?.label}</div>
              </div>
              <div className="WhyUs_Section_Box_Description">
                <div>
                  <Link to={`/products?catName=${item?.label}`} style={{ textDecoration: 'none', color: 'black' }}>
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          ))}
          <RightOutlined
            onClick={handleNext}
            style={{
              cursor:
                currentIndex + itemsPerPage >= Favorite_Products.length
                  ? "not-allowed"
                  : "pointer",
            }}
            disabled={currentIndex + itemsPerPage >= Favorite_Products.length}
          />
        </div>
      </div>
    </WhyUsSectionAlign>
  );
};

export default ShopByCategory;

const WhyUsSectionAlign = styled.div`
  margin: 40px 0;

  .home_wrapper {
    padding: 0 60px;
    max-width: 90%;
  }

  .Top_Title {
    text-align: center;
    padding: 0px 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 50%;
    margin: auto;

    p {
      font-family: "Josefin Sans", sans-serif;
      font-size: 25px;
      font-weight: 600;
      margin: auto;
    }
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .WhyUs_Section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1%;
    flex-wrap: wrap;
    width: 100%;
  }

  .WhyUs_Section_Box {
    width: 20%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-bottom: 20px;
  }

  .WhyUs_Section_Box_Image {
    width: 100%;
    height: 55vh;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #ececec;
  }

  .WhyUs_Section_Box:nth-child(even) .WhyUs_Section_Box_Image {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  /* Second Box (even elements) - border bottom radius */
  .WhyUs_Section_Box:nth-child(odd) .WhyUs_Section_Box_Image {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .WhyUs_Section_Box_Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .WhyUs_Section_Box_Title {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    text-align: center;
    line-height: normal;
    height: 2.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .WhyUs_Section_Box_Description {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 10px;
    line-height: normal;
    height: 2em;
    overflow: hidden;
  }

  @media (max-width: 912px) {
    .WhyUs_Section_Box {
      width: 30%;
    }
  }

  @media (max-width: 768px) {
    .WhyUs_Section_Box {
      width: 30%;
    }
  }

  @media (max-width: 525px) {
    .WhyUs_Section_Box {
      width: 48%;
    }
    .Top_Title{
      width: 100%;
    }
  }

  @media (max-width: 475px) {
    .WhyUs_Section_Box {
      width: 100%;
    }
    .home_wrapper {
      padding: 0 0px;
    }
    .Top_Title{
      width: 100%;
    }
    .WhyUs_Section_Box:nth-child(even) .WhyUs_Section_Box_Image {
      border-top-left-radius: 0%;
      border-top-right-radius: 0%;
    }

    /* Second Box (even elements) - border bottom radius */
    .WhyUs_Section_Box:nth-child(odd) .WhyUs_Section_Box_Image {
      border-bottom-left-radius: 0%;
      border-bottom-right-radius: 0%;
    }
  }
`;
