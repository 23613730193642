import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DownloadOutlined } from "@ant-design/icons";
import {
  List,
  Image,
  Card,
  Typography,
  Divider,
  Tag,
  Button,
  Modal,
  Input,
  Radio,
  Space,
  Form,
  message,
} from "antd";
import { styles } from "../ApiService/Data";
import API from "../ApiService/ApiService";
import SideBar from "./SideBar";
import MyProfile from "./MyProfile";

export default function MyOrder(props) {
  const api = new API();
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(1);
  const [data, setData] = useState("");
  const [order, setOrderList] = useState([]);
  const [returnMessage, setreturnMessage] = useState([]);
  const user = JSON.parse(localStorage.getItem("user")) || "";
  // console.log("user",user)
  useEffect(() => {
    getAllOrder();
    getAllreturnmessage();
  }, []);
  const data4Checkout = {
    buyer_id: user?._id,
  };
  const getAllOrder = () => {
    api.getmyorder(data4Checkout).then((res) => {
      // console.log("res--->", res);
      setOrderList(res?.data?.data);
    });
  };
  const getAllreturnmessage = () => {
    api.getCommonAllforUser("returnmessages").then((res) => {
      setreturnMessage(res?.data?.data);
    });
  };

  const Createreturn = async (data) => {
    const returnmessage_id = {
      returnmessage_id: data,
      order_id: orderId,
    };
    // console.log(returnmessage_id)
    const response = await api.createCommon(
      "returncustomers",
      returnmessage_id
    );
    if (response?.data?.success) {
      form.resetFields();
      message.success("Successfully Added");
      navigate("/my-order");
    } else {
      message.error(response?.response?.data?.message);
    }
  };
  const downloadInvoice = (id) => {
    // console.log(id)
    api.getInvoice(id).then((res) => {
      const printWindow = window.open("", "_blank", "width=500,height=600");

      if (printWindow) {
        printWindow.document.write(res?.data);
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.print();
        };
      } else {
        console.error("Failed to open print window");
      }
    });
  };

  const cancelModel = (values) => {
    setOrderId(values);
    setIsModalOpen(true);
  };

  const handleOk = (value) => {
    console.log("handleOkvalue", value);
    if(value == 1){
      message.warning("Please select return reason")
    } else {
      Createreturn(value);
      setIsModalOpen(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  return (
    <React.Fragment>
      <Section style={{ padding: "30px 40px" }}>
        <Wrapper>
          <Title>My Order</Title>
          <Align>
            <Left>
              <SideBar />
            </Left>
            <Right className="Right">
              <div className="Title">
                <h5>Order Detail</h5>
                <h5 className="Title_Mobile">Date</h5>
                <h5> Status</h5>
                <h5 className="Title_Mobile">Total Price</h5>
                <h5>Action</h5>
              </div>
              <Divider />
              <div className="container">
                {order?.map((item) => (
                  // console.log("item----->", item),
                  <>
                    <div className="Order">
                      <StyledCard key={item?._id}>
                        <CardContent>
                          <Typography.Paragraph>
                            <div className="title-value-container">
                              <span className="title">Order No:</span>
                              <span className="value">
                                {item?.order?.order_no}
                              </span>
                            </div>
                            <div className="separator"></div>
                            <div className="separator"></div>
                            <div className="title-value-container">
                              <span className="title">Total Products:</span>
                              <span className="value">
                                &nbsp;{item?.order?.products?.length}
                              </span>
                            </div>
                            <div className="Mobile_separator"></div>
                            <div className="Mobile_title-value-container">
                              <span className="Mobile_title">Date:</span>
                              <span className="Mobile_value">
                                {new Date(item?.order?.date)?.toLocaleString(
                                  "en-IN",
                                  {
                                    dateStyle: "medium",
                                  }
                                )}
                              </span>
                            </div>
                          </Typography.Paragraph>
                          <div className="Date_Value">
                            <span className="value">
                              {" "}
                              {new Date(item?.order?.date)?.toLocaleString(
                                "en-IN",
                                {
                                  dateStyle: "medium",
                                }
                              )}
                            </span>
                          </div>

                          <Status className="status">
                            {item?.order?.status === "Cancel" ? (
                              <Tag color="red">{item?.order?.status}</Tag>
                            ) : (
                              <Tag color="green">{item?.order?.status}</Tag>
                            )}
                          </Status>
                          <StatusAndPrice>
                            <Price>
                              <div className="price">
                                {item?.order?.total?.toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                  style: "currency",
                                  currency: "INR",
                                })}
                              </div>
                            </Price>
                          </StatusAndPrice>
                          <div className="actions">
                            <Link
                              to={`order/${item?.order?._id}`}
                              type="primary"
                              style={{ textDecoration: "none" }}
                            >
                              View Detail
                            </Link>

                            {item?.order?.status == "Cancel" ? null : (
                              <Button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  downloadInvoice(item?.order?._id)
                                }
                              >
                                <DownloadOutlined /> Invoice
                              </Button>
                            )}
                            {item?.order?.status === "Pending" && (
                              <Button
                                type="primary"
                                onClick={() => cancelModel(item?.order?._id)}
                              >
                                Cancel
                              </Button>
                            )}
                          </div>
                        </CardContent>
                      </StyledCard>
                    </div>
                    <Divider />
                  </>
                ))}
              </div>
            </Right>
          </Align>
        </Wrapper>
      </Section>
      <Modal
        title="Reason to Cancel"
        open={isModalOpen}
        onOk={() => handleOk(value)}
        onCancel={handleCancel}
      >
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {returnMessage.map((item, i) => (
              <Radio key={i} value={item?._id}>
                {item?.returnmessage}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Modal>
    </React.Fragment>
  );
}

const Section = styled.section`
  margin: 0px 0 50px 0;
  width: 100%;
  position: relative;
  display: inline-block;

  .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .ant-card-body {
    padding: 36px;
  }

  @media screen and (max-width: 1024px) {
    .container {
      width: 100% !important;
      margin: 0px !important;
    }
    .Side_BarMain {
      min-width: 0% !important;
      width: 130px !important;
    }
    .Side_BarMain ul {
      padding: 0;
    }
    .laQDLI {
      min-width: 0% !important;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 100% !important;
      margin: 0px !important;
    }
    .Date_Value {
      display: none;
    }
    .Order {
      width: 100% !important;
      margin: 0px !important;
    }
    .Title h5 {
      width: 100% !important;
      text-align: center;
    }
    .Title .Title_Mobile {
      display: none;
    }
    .status {
      margin-left: 60px;
    }
  }

  @media screen and (max-width: 541px) {
    padding: 60px 10px !important;
    .container {
      width: 100% !important;
      margin: 0px !important;
    }
    .Date_Value {
      display: none;
    }
    .Order {
      width: 100% !important;
      margin: 0px !important;
    }
    .Title h5 {
      width: 100% !important;
      text-align: center;
    }
    .Title .Title_Mobile {
      display: none;
    }
    .status {
      margin-left: 30px;
    }
    .ant-typography {
      font-size: 12px !important;
      width: 100px !important;
    }
    .actions {
      font-size: 12px !important;
      /* border: 1px solid red; */
      margin-right: -20px !important;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 60px 10px !important;
    .container {
      width: 100% !important;
      margin: 0px !important;
    }
    .Date_Value {
      display: none;
    }
    .Order {
      width: 100% !important;
    }
    .Title h5 {
      width: 100% !important;
      text-align: center;
    }
    .Title .Title_Mobile {
      display: none;
    }
    .status {
      margin-left: 30px;
    }
    .ant-typography {
      font-size: 12px !important;
      width: 100px !important;
    }
    .actions {
      font-size: 12px !important;
      /* border: 1px solid red; */
      margin-right: -10px !important;
    }
    .title {
      width: max-content !important;
    }
    .ant-card-body {
      padding: 30px 0px 30px 0px !important;
    }
    .ant-btn {
      font-size: 12px;
      height: 25px;
      padding: 1px 6px;
    }
    .ant-divider-horizontal {
      margin: 10px 0;
    }
    .Right {
      padding: 10px;
    }
  }

  @media screen and (max-width: 375px) {
    padding: 60px 10px !important;
    .container {
      width: 100% !important;
      margin: 0px !important;
    }
    .Date_Value {
      display: none;
    }
    .Order {
      width: 100% !important;
    }
    .Title h5 {
      width: 100% !important;
      text-align: center;
    }
    .Title .Title_Mobile {
      display: none;
    }
    .status {
      margin-left: 10px;
    }
    .ant-typography {
      font-size: 12px !important;
      width: 100px !important;
    }
    .actions {
      font-size: 12px !important;
      /* border: 1px solid red; */
      margin-right: -8px !important;
    }
    .title {
      width: max-content !important;
    }
    .ant-card-body {
      padding: 30px 0px 30px 0px !important;
    }
    .ant-btn {
      font-size: 12px;
      height: 25px;
      padding: 1px 6px;
    }
    .ant-divider-horizontal {
      margin: 10px 0;
    }
    .Right {
      padding: 10px;
    }
  }

  @media screen and (max-width: 320px) {
    padding: 60px 10px !important;
    .container {
      width: 100% !important;
      margin: 0px !important;
      padding: 0 0px !important;
    }
    .Date_Value {
      display: none;
    }
    .Order {
      width: 100% !important;
    }
    .Title {
      /* margin-left: 0px !important; */
    }
    .Title h5 {
      width: 100% !important;
      text-align: center;
    }
    .Title .Title_Mobile {
      display: none;
    }
    .status {
      margin-left: 10px;
    }
    .ant-typography {
      font-size: 10px !important;
      width: 100px !important;
    }
    .actions {
      font-size: 10px !important;
      /* border: 1px solid red; */
      margin-right: 0 !important;
    }
    .title {
      width: max-content !important;
    }
    .ant-card-body {
      padding: 12px 0px 12px 0px !important;
    }
    .ant-btn {
      font-size: 10px;
      height: 25px;
      padding: 1px 5px;
    }
    .ant-divider-horizontal {
      margin: 10px 0;
    }
    .Right {
      padding: 1px;
    }
  }
`;
const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 0 0 25px;
`;
const Wrapper = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: auto;
`;
const Align = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
`;
const Left = styled.div`
  margin-top: -21px;
  width: 25%;
  display: inline-block;
  /* border: 1px solid ${styles.light}; */
  padding: 24px;
  @media screen and (max-width: 956px) {
    width: 100%;
    margin: 0 0 50px;
  }
`;
const Right = styled.div`
  .Title h5 {
    width: 100% !important;
    text-align: center;
  }

  .container {
    margin: 0px;
    width: 100%;
    overflow-y: auto;
    max-height: 500px;
    overflow-x: hidden;
  }
  width: 72%;
  display: inline-block;
  border: 1px solid ${styles.light};
  padding: 24px;

  @media screen and (max-width: 956px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .gUxcQX {
      padding: 0px !important;
    }

    /* .container {
  margin: 0px;
  width: 100%;
  overflow-x: auto;
  max-height: 400px;
  overflow-y: auto;
} */
    .Title h5 {
      width: 95%;
      font-size: medium;
    }
  }

  .Title {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    position: sticky;
  }

  .Title h3 {
    font-weight: 300;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  border: none;
  @media screen and (max-width: 768px) {
  }
`;

const ImageContainer = styled.div`
  text-align: center;
`;

const CardContent = styled.div`
  padding: 5px 2px 3px 2px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;

  @media screen and (max-width: 2000px) and (min-width: 780px) {
    .Mobile_title-value-container {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
  }

  .title-value-container {
    /* padding-top:2px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-typography {
    padding-top: 13px;
  }

  .title {
    font-weight: bold;
  }

  .Mobile_title {
    font-weight: bold;
  }

  .value {
    flex-grow: 1;
    text-align: right;
  }

  .Mobile_value {
    flex-grow: 1;
    text-align: right;
  }

  .separator {
    margin: 2px 0;
  }
`;

const StatusAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Status = styled.p`
  margin: 0;
  font-size: 20px;
`;

const Price = styled.p`
  margin: 0;
  font-size: 18px;
  padding-bottom: 15px;

  @media screen and (max-width: 768px) {
    .price {
      display: none;
    }
  }
`;

const FormAlign = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const FormLeft = styled.div`
  width: 65%;
  display: inline-block;
  position: relative;
  .ant-row {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .ant-form-item-label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }
  .ant-form-item {
    margin: 0 0 24px;
    display: inline-block;
    width: 100%;
  }
  .ant-form-item:nth-child(5) {
    width: 100%;
    display: inline-block;
  }
  .ant-form-item:nth-child(1),
  .ant-form-item:nth-child(3) {
    width: 48%;
    float: left;
  }
  .ant-form-item:nth-child(2),
  .ant-form-item:nth-child(4) {
    width: 48%;
    float: right;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    .ant-form-item:nth-child(1),
    .ant-form-item:nth-child(3) {
      width: 100%;
      float: left;
    }
    .ant-form-item:nth-child(2),
    .ant-form-item:nth-child(4) {
      width: 100%;
      float: right;
    }
  }
`;

const FormRight = styled.div`
  width: 30%;
  display: inline-block;
  position: relative;
`;

const CardRight = styled.div`
  text-align: right;
`;
