import React from "react";
import PriceList from "../../Ecommerce/PriceList";
import styled from "styled-components";

const CheckoutCard = ({setTotalAmount, totalAmount, refresh}) => {
  // console.log("CheckoutCardrefresh--->", refresh);
  return (
    <CardAlign>
      <div className="checkout">
        <PriceList setTotalAmount={setTotalAmount} totalAmount={totalAmount} refresh={refresh} />
      </div>
    </CardAlign>
  );
};

export default CheckoutCard;

const CardAlign = styled.div`
  width: 100% !important;

  @media screen and (max-width: 768px) {
    .checkout {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 480px) {
    .checkout {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 375px) {
    .checkout {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 360px) {
    .checkout {
      width: 100% !important;
    }
  }  

  @media screen and (max-width: 320px) {
    .checkout {
      width: 100% !important;
    }
  }
`;
