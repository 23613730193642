import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Divider, Tooltip } from "antd";
import { Link } from "react-router-dom";

const TC14 = ({ category, content_all }) => {
  const scrollRef = useRef(null);
  const isDragging = useRef(false);
  const startPos = useRef(0);
  const scrollLeft = useRef(0);

  // Handle click and drag scroll (mouse)
  const handleMouseDown = (e) => {
    isDragging.current = true;
    scrollRef.current.style.cursor = "grabbing";
    scrollRef.current.style.userSelect = "none"; // Prevents text selection
    startPos.current = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault(); // Prevents default drag behavior
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startPos.current) * 2; // Scroll speed multiplier
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    scrollRef.current.style.cursor = "grab";
    scrollRef.current.style.removeProperty("user-select"); // Restore text select
  };

  // Handle touch events for mobile scrolling
  const handleTouchStart = (e) => {
    isDragging.current = true;
    startPos.current = e.touches[0].pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
    const walk = (x - startPos.current) * 2; // Scroll speed multiplier
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  // Horizontal scroll with mouse wheel
  useEffect(() => {
    const handleWheel = (e) => {
      if (scrollRef.current) {
        e.preventDefault(); // Prevent vertical scroll
        scrollRef.current.scrollLeft += e.deltaY; // Scroll horizontally based on vertical wheel movement
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener("wheel", handleWheel);

    return () => {
      scrollElement.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <CategoryAlign>
      <div className="home_wrapper">
        <Divider />
        <TopCategorySection
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseUp}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart} // Touch events for mobile
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="TopCategory_Main">
            {content_all?.zigo_category?.map((category, index) => (
              <Link key={index} to={`/products?catName=${category?.label}`}>
                <div className="top_category" key={index}>
                  <CategoryName>
                      <p className="category_name_text">{category?.label}</p>
                  </CategoryName>
                </div>
              </Link>
            ))}
          </div>
        </TopCategorySection>
        <Divider />
      </div>
    </CategoryAlign>
  );
};

export default TC14;

const CategoryAlign = styled.div`
  .home_wrapper {
    max-width: 100%;
    margin: auto;
  }

  .ant-divider-horizontal {
    margin: 0;
  }
`;

const TopCategorySection = styled.div`
  width: 90%;
  margin: auto;
  /* width: 100%; */
  display: flex;
  overflow: hidden;
  padding: 1rem 0;
  cursor: grab;
  user-select: none; /* Prevent text selection */

  &:active {
    cursor: grabbing;
  }

  .TopCategory_Main {
    display: flex;
    align-items: center;
    gap: 2rem;
    /* Hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .TopCategory_Main::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .top_category {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }

  .category_name_text {
    margin: 0;
    padding: 0.5rem;
    color: #052c49;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    border-bottom: 2px solid transparent;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .category_name_text:hover {
    border-bottom: 2px solid #0bb68c;
  }

  @media (max-width: 768px) {
    .TopCategory_Main {
      gap: 1rem;
    }

    .category_name_text {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 520px) {
    .TopCategory_Main {
      gap: 0.5rem;
    }

    .category_name_text {
      font-size: 0.8rem;
    }
  }
`;

const CategoryName = styled.div`
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 519px) {
    .category_name_text {
      font-size: 0.8rem;
    }
  }
`;
