import axios from "axios";
import { company_code, base_url, api_website, api_admin } from "../config";

const isToken = localStorage.getItem("token") || "";
// console.log("isToken", isToken);

//token request
export const userRequest = axios.create({
  baseURL: api_website,
  headers: {
    authorization: `Bearer ${isToken}`,
    // company_code: `ecDigi ${company_code}`,
  },
 
});

//for admin api call
export const userRequest1 = axios.create({
  baseURL: api_admin,
  headers: {
    authorization: `Bearer ${isToken}`,
    // company_code: `ecDigi ${company_code}`,
  },
 
});

//public request
export const publicRequest = axios.create({
  baseURL: api_admin,
});
