import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const OurSignatureCollections2 = ({ content_all }) => {
  const AllProducts = useSelector(
    (state) => state?.product?.product?.data?.[0]?.data
  );

  const [largestImageIndex, setLargestImageIndex] = useState(0);

  useEffect(() => {
    const findLargestImage = () => {
      let maxHeight = 0;
      let maxIndex = 0;

      content_all?.zigo_oursign_product_2?.forEach((item, index) => {
        const img = new Image();
        img.src = process.env.REACT_APP_BASE + item?.image;

        img.onload = () => {
          if (img.height > maxHeight) {
            maxHeight = img.height;
            maxIndex = index;
          }
          setLargestImageIndex(maxIndex);
        };
      });
    };
    findLargestImage();
  }, [content_all]);

  return (
    <Section>
      <div className="home_wrapper">
        <div className="Top_Title">
          <div className="ViewAll">
            <p>{content_all?.title_1}</p>
          </div>
          <p className="Title">{content_all?.title_2}</p>
        </div>

        <div className="grid-container">
          {/* Rendering the largest image */}
          <div className="grid-item large">
            <Link to="/products">
              <img
                src={
                  process.env.REACT_APP_BASE +
                  content_all?.zigo_oursign_product_2?.[largestImageIndex]?.image
                }
                alt=""
              />
            </Link>
            <div className="overlay">
              <h2>
                {content_all?.zigo_oursign_product_2?.[largestImageIndex]?.title}
              </h2>
              <p>{content_all?.zigo_oursign_product_2?.[largestImageIndex]?.description}</p>
            </div>
          </div>

          {/* Rendering other images except the largest */}
          {content_all?.zigo_oursign_product_2
            ?.filter((_, index) => index !== largestImageIndex)
            .map((item, index) => (
              <div className="grid-item small" key={index}>
                <Link to="/products">
                  <img src={process.env.REACT_APP_BASE + item?.image} alt={item?.title} />
                </Link>
                <div className="content">
                  <h3>{item?.title}</h3>
                  <p>{item?.price}</p>
                </div>
              </div>
            ))}

          <div className="view-all">
            <Link to="/products" style={{ textDecoration: "none", color: "#038175" }}>
              View All
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default OurSignatureCollections2;

const Section = styled.div`
  /* height: 120vh; */

  .home_wrapper {
    padding: 50px 80px;
    height: 100%;
    max-width: 90%;
  }

  .Top_Title {
    text-align: center;
    padding: 0px 0 0px 0;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 50%;
    margin: auto;

    p {
      font-family: Inter;
      font-size: 25px;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  .ViewAll {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
    }
  }

  .Title {
    font-family: "Josefin Sans", sans-serif;
  }

  .grid-container {
    display: grid;
    grid-template-areas:
      "large-item small-item-1 small-item-2"
      "large-item small-item-3 small-item-4"
      "view-all view-all view-all";
    grid-gap: 16px;
    margin: 20px;
    height: 100%;
  }

  .grid-item.large {
    grid-area: large-item;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; 
      background-color: #f9f9f9; /* Optional background color for padding */
    }
  }

  .grid-item.small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain; /* Use contain to avoid cropping */
      background-color: #f9f9f9; /* Optional background color */
    }
  }

  .overlay,
  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateY(20px);
  }

  .grid-item:hover .overlay,
  .grid-item:hover .content {
    opacity: 1;
    transform: translateY(0);
  }

  .view-all {
    grid-area: view-all;
    text-align: center;
    margin-top: 20px;
    color: #038175;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    /* height: auto !important;
    .home_wrapper {
      padding: 0 5px;
    }
    .grid-container {
      grid-template-areas:
        "large-item"
        "small-item-1"
        "small-item-2"
        "small-item-3"
        "small-item-4"
        "view-all";
      grid-template-columns: 1fr;
      height: auto; 
    }

    .grid-item.large,
    .grid-item.small {
      width: 100%;
    } */

    .Top_Title {
      width: 100%;
    }
  }

  @media (max-width: 475px) {
    height: auto !important;
    .home_wrapper {
      padding: 0 0px;
      height: auto;
    }
    .grid-container {
      grid-template-areas:
        "large-item"
        "small-item-1"
        "small-item-2"
        "small-item-3"
        "small-item-4"
        "view-all";
      grid-template-columns: 1fr;
      height: auto; /* Allow scrolling on smaller screens */
    }

    .grid-item.large,
    .grid-item.small {
      width: 100%;
    }

    .Top_Title {
      width: 100%;
    }
  }
`;
