
//Local Zigoo
// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'http://192.168.100.212:7000/api/v1/website/';
// export const api_admin = 'http://192.168.100.212:7000/api/v1/admin/';
// export const base_url = 'http://192.168.100.212:7000/';
// export const app_title = 'ecDigi Technologies';

//Live Zigoo Domine//
// export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
// export const api_website = 'https://ap.ecdigi.com/api/v1/website/';
// export const api_admin = 'https://ap.ecdigi.com/api/v1/admin/';
// export const base_url = 'https://ap.ecdigi.com/';
// export const app_title = 'ecDigi Technologies';

//Live Zigoo Domine client//
export const company_code = '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg';
export const api_website = 'https://www.zigoo.deals/api/v1/website/';
export const api_admin = 'https://www.zigoo.deals/api/v1/admin/';
export const base_url = 'https://www.zigoo.deals/';
export const app_title = 'ecDigi Technologies';