import React, { useRef } from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import { Link } from "react-router-dom";
import {
  LeftOutlined,
  RightOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import BannerImg1 from "../../Assets/Images/Zigoo/Zigoo banner.png";
import BannerImg2 from "../../Assets/Images/Zigoo/Zigoo banner.png";
import BannerImg3 from "../../Assets/Images/Zigoo/Zigoo banner.png";
import "../../Assets/Fonts/fonts.css";

const contentStyle = {
  // height: "100vh",
  // height: "auto",
  // width: "100%",
  // objectFit: "fill",
  height: "100%", // Ensure the container takes up the available space
  width: "100%", // Set the image to be fully responsive
  objectFit: "contain", // Maintain aspect ratio without stretching
};

const overlayStyle = {
  position: "absolute",
  top: "45%",
  left: "30%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  width: "30%",
};

const slideStyle = {
  position: "relative",
};

const Banner14 = ({ content_all }) => {
  // console.log("content_allBanner12", content_all);
  const carouselRef = useRef(null);

  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  const content_all1 = {
    bannersOneWear: [
      {
        banner_img: {
          image: BannerImg1,
        },
        description_main: "GOLD LABEL",
        description_sub: "EXCLUSIVELY PROFESSIONAL STYLING RANGE",
        offer_percent: "50% OFF",
      },
      {
        banner_img: {
          image: BannerImg1,
        },
        description_main: "GOLD LABEL",
        description_sub: "EXCLUSIVELY PROFESSIONAL STYLING RANGE",
        offer_percent: "50% OFF",
      },
      {
        banner_img: {
          image: BannerImg1,
        },
        description_main: "GOLD LABEL",
        description_sub: "EXCLUSIVELY PROFESSIONAL STYLING RANGE",
        offer_percent: "50% OFF",
      },
    ],
  };

  return (
    <BannerSection>
      <Carousel ref={carouselRef} arrows={false} autoplay fade infinite={true}>
        {content_all?.zigoobanner?.map((item, index) => (
          <div style={slideStyle} key={index}>
            {item?.banner_link ? (
              <Link key={index} to={`${item?.banner_link}`}>
                <img
                className="banner-image"
                src={process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image}
                style={contentStyle}
              />
                {/* <img
                  className="banner-image"
                  srcSet={`${
                    process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image
                  } 1820w, 
          ${
            process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image_800px
          } 800w`}
                  sizes="(max-width: 1440px) 100vw, 1820px"
                  src={
                    process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image
                  }
                  alt="Banner"
                  style={contentStyle}
                /> */}
              </Link>
            ) : (
                 <img
                 className="banner-image"
                 src={process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image}
                 style={contentStyle}
               />
          //     <img
          //       className="banner-image"
          //       srcSet={`${
          //         process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image
          //       } 1820w, 
          // ${
          //   process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image_800px
          // } 800w`}
          //       sizes="(max-width: 1440px) 100vw, 1820px"
          //       src={process.env.REACT_APP_BASE + item?.zigoo_banner_img?.image}
          //       alt="Banner"
          //       style={contentStyle}
          //     />
            )}
            <div className="overlay" style={overlayStyle}>
              <p>{item?.description_main}</p>
              <h2>{item?.description_sub}</h2>
              <h2>{item?.Offer_txt}</h2>
              {item?.description_main && (
                <div
                  className="custom-button-wrapper"
                  style={{
                    width: "32%",
                  }}
                >
                  <Button
                    className="custom-button"
                    ghost
                    style={{
                      borderRadius: "0.3em",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: "#fff",
                      color: "#000",
                    }}
                    onClick={() =>
                      (window.location.href = `${item?.banner_link}`)
                    }
                  >
                    Shop now <ArrowRightOutlined />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </Carousel>
      <ArrowLeft onClick={() => carouselRef.current.prev()}>
        <ArrowLeftOutlined />
      </ArrowLeft>
      <ArrowRight onClick={() => carouselRef.current.next()}>
        <ArrowRightOutlined />
      </ArrowRight>
    </BannerSection>
  );
};

export default Banner14;

const BannerSection = styled.div`
  position: relative;

  .overlay {
    h2 {
      font-size: 30px;
      font-family: "Josefin Sans", sans-serif;
      font-weight: 500;
      color: #000;
    }

    p {
      color: #038175;
      letter-spacing: 1em;
      font-family: "Sawarabi Gothic", sans-serif;
    }
  }

  .custom-button:hover {
    background-color: #000 !important;
    color: #fff !important;
    border: none;
    /* border: 1px solid #ffffff !important; */
  }

  @media (max-width: 1440px) {
    max-width: 100%;
    .banner-image {
      /* height: 70vh !important; */
      height: auto;
      width: 100%;
      height: auto; /* This ensures no stretching */
      object-fit: contain; /* Keeps the aspect ratio */
    }
  }

  @media (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) {
    max-width: 100%;
    .banner-image {
      /* height: 95vh !important; */
      height: auto;
    }
  }

  @media (max-width: 1330px) {
    max-width: 100%;
    .banner-image {
      /* height: 70vh !important; */
      height: auto;
    }
  }

  @media (min-width: 1180px) and (max-width: 1180px) and (orientation: landscape) {
    max-width: 100%;
    .banner-image {
      /* height: 65vh !important; */
      height: auto;
    }
  }

  @media (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) {
    max-width: 100%;
    .banner-image {
      /* height: 40vh !important; */
      height: auto;
    }
  }

  @media (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) {
    max-width: 100%;
    .banner-image {
      /* height: 57vh !important; */
      height: auto;
    }
  }

  @media (min-width: 912px) and (max-width: 912px) and (orientation: portrait) {
    max-width: 100%;
    .banner-image {
      /* height: 40vh !important; */
      height: auto;
    }
  }

  @media (max-width: 860px) {
    .banner-image {
      /* height: 35vh !important; */
      height: auto;
    }
    .overlay {
      h2 {
        font-size: 3vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 1.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .banner-image {
      /* height: 35vh !important; */
      height: auto;
    }
    .overlay {
      h2 {
        font-size: 3vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 1.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (min-width: 720px) and (max-width: 720px) and (orientation: landscape) {
    .banner-image {
      /* height: 57vh !important; */
      height: auto;
    }
  }

  @media (max-width: 480px) {
    .banner-image {
      /* height: 35vh !important; */
      height: auto;
    }
    .overlay {
      h2 {
        font-size: 4vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
  @media (max-width: 380px) {
    .banner-image {
      /* height: 35vh !important; */
      height: auto;
    }
    .overlay {
      h2 {
        font-size: 3.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 1.5; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 75%;
  width: 40px;
  height: 40px;
  background: #fff;
  color: #000;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transform: translateY(200%);
  font-size: 18px;

  @media (max-width: 1330px) {
    top: 55%;
  }

  @media (max-width: 1280px) {
    top: 70%;
  }

  @media (min-width: 1180px) and (max-width: 1180px) and (orientation: landscape) {
    top: 73%;
  }

  @media (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) {
    top: 75% !important;
  }

  @media (min-width: 912px) and (max-width: 912px) and (orientation: portrait) {
    top: 75% !important;
  }

  @media (min-width: 820px) and (max-width: 820px) and (orientation: portrait) {
    top: 65% !important;
  }

  @media (max-width: 1024px) {
    top: 70%;
  }

  @media (max-width: 768px) {
    top: 20%;
  }

  @media (min-width: 768px) and (max-width: 768px) and (orientation: portrait) {
    top: 60%;
  }

  @media (min-width: 720px) and (max-width: 720px) and (orientation: landscape) {
    top: 55%;
  }

  @media (min-width: 1024px) and (max-width: 1024px) and (orientation: landscape) {
    top: 65%;
  }

  @media (max-width: 430px) {
    top: 60%;
  }

  @media (max-width: 414px) {
    top: 55%;
  }

  @media (max-width: 375px) {
    top: 40%;
  }

  @media (max-width: 360px) {
    top: 50%;
  }
`;

const ArrowLeft = styled(Arrow)`
  bottom: 10px;
  right: 60px;
`;

const ArrowRight = styled(Arrow)`
  bottom: 10px;
  right: 10px;
`;
