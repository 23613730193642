import React from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import Image1 from "../../Assets/Images/GirlsHub Template/Banner-3.png";
import Image2 from "../../Assets/Images/GirlsHub Template/Banner-4.png";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const HCGirlsHub2 = ({banner, content_all}) => {
  // console.log("banner", banner);
  // console.log("content_all==>HCGirlsHub2", content_all)

  const api = new API();
  const navigate = useNavigate();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        {(<div className="Banner_Main">
          <div className="Left_Section" >
            <Link to={`/${content_all?.left_banner_img_bottom_link}`} style={{textDecoration: "none"}}>
            <img src={api?.rootUrl1 + content_all?.left_banner_img_bottom?.image} alt="Banner" />
            </Link>
          </div>
          <div className="Right_Section">
            <Link to={`/${content_all?.right_banner_img_bottom_link}`} style={{textDecoration: "none"}}>
            <img src={api?.rootUrl1 + content_all?.right_banner_img_bottom?.image} alt="Banner" />
            </Link>
          </div>
        </div>)}
      </div>
    </BannerAlign>
  );
};

export default HCGirlsHub2;

const BannerAlign = styled.div`
  .home_wrapper {
    display: flex;
    padding: 60px 0;
  }

  .Banner_Main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Banner_Main .Left_Section,
  .Banner_Main .Right_Section {
    width: 50%;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    max-width: 912px;
  }

  @media screen and (min-width: 820px) and (max-width: 911px) {
    max-width: 820px;
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {
    max-width: 768px;
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    max-width: 430px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    max-width: 425px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    max-width: 414px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    max-width: 412px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    max-width: 390px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }    
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    max-width: 375px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 360px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }    
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    max-width: 320px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;
    .Banner_Main {
      flex-direction: column;
    }
    .Banner_Main .Left_Section,
    .Banner_Main .Right_Section {
      width: 100%;
    }
  }
`;
