import React from "react";
import image from "../../Assets/Images/GirlsHub Template/WhyUs.png";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Image1 from "../../Assets/Images/GirlsHub Template/Banner-3.png";
import Image2 from "../../Assets/Images/GirlsHub Template/Banner-4.png";
import API from "../../ApiService/ApiService";

const WhyUsSection4 = ({whyus_banner, content_all}) => {
  // console.log("whyus_banner", whyus_banner);
  // console.log("content_all==>whyus_banner", content_all)
  const api = new API();

  const navigate = useNavigate();
  return (
    <WhyUsAlign>
      <div className="home_wrapper">
        <div className="WhyUs_Section_Main">
          <div className="Left_Section">
            <img src={api?.rootUrl1 + content_all?.why_us_banner_img?.image} alt="Image" />
          </div>
          <div className="Right_Section">
            <div className="Right_Title">
              <span className="Title1">
                {/* Why You Choose us? */}
                {content_all?.why_us_title_question}
                </span>
            </div>
            <div className="Right_Sub_Title">
              <span>
                {/* Our specially designed maternity wear */}
                {content_all?.why_us_title}
                </span>
            </div>
            <div className="Right_List">
              <ul className="ft_Section">
                {/* <li>
                  -Minimal inner seams for a smooth feel against the skin.
                </li>
                <li>-With special stitching that’s gentle on the skin.</li>
                <li>
                  -Highly stretchable fabric with a fit that won’t compress your
                  bump
                </li> */}
                <div dangerouslySetInnerHTML={{__html: content_all?.why_description}} />
                {/* <li>
                  {whyus_banner?.banner_content}
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </WhyUsAlign>
  );
};

export default WhyUsSection4;

const WhyUsAlign = styled.div`
  .home_wrapper {
    padding: 60px 60px;
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }

  .WhyUs_Section_Main .Left_Section {
    width: 450px;
    height: 450px;
    flex-shrink: 0;
  }

  .Right_Section .Right_Title {
    color: #000;
    font-family: Moneta;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 20px;
  }

  .Right_Section .Right_Sub_Title {
    color: #f65c7f;
    font-family: Euclid Circular A;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
  }

  .Right_Section .Right_List {
    margin-top: 20px;
  }

  .Right_Section .Right_List li {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    padding: 10px 0;
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-width: 768px;
    .WhyUs_Section_Main {
      flex-direction: column;
    }    
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
    .WhyUs_Section_Main {
      flex-direction: column;
    }
    .Right_Section .Right_Title {
      font-size: 25px;
      text-align: center;
    }
    .Right_Section .Right_Sub_Title {
      font-size: 20px;
      text-align: center;
    }
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    max-width: 425px;
    .WhyUs_Section_Main {
      flex-direction: column;
    }
    .Right_Section .Right_Title {
      font-size: 25px;
      text-align: center;
    }
    .Right_Section .Right_Sub_Title {
      font-size: 20px;
      text-align: center;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 424px) {
    max-width: 375px;
    .WhyUs_Section_Main {
      flex-direction: column;
    }
    .Right_Section .Right_Title {
      font-size: 25px;
      text-align: center;
    }
    .Right_Section .Right_Sub_Title {
      font-size: 20px;
      text-align: center;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 374px) {
    max-width: 320px;
    .WhyUs_Section_Main {
      flex-direction: column;
    }
    .Right_Section .Right_Title {
      font-size: 25px;
      text-align: center;
    }
    .Right_Section .Right_Sub_Title {
      font-size: 20px;
      text-align: center;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;
    .WhyUs_Section_Main {
      flex-direction: column;
    }
    .Right_Section .Right_Title {
      font-size: 25px;
      /* text-align: center; */
    }
    .Right_Section .Right_Sub_Title {
      font-size: 20px;
      /* text-align: center; */
    }
  }
`;
